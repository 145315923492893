export const pluralInstallmentsWord = (quantity: number | undefined): string => {
  return quantity === 1 ? 'cuota' : 'cuotas';
};

export const cardIcon = (cardType: string): string => {
  if (cardType === 'Visa') {
    return 'https://storage.googleapis.com/onlypays-public/assets/images/visa_icon.png';
  } else if (cardType === 'AmericanExpress') {
    return 'https://storage.googleapis.com/onlypays-public/assets/images/amex_icon.png';
  } else if (cardType === 'MasterCard') {
    return 'https://storage.googleapis.com/onlypays-public/assets/images/mastercard_icon.png';
  } else if (cardType === 'Diners') {
    return 'https://storage.googleapis.com/onlypays-public/assets/images/diners_icon.png';
  } else if (cardType === 'Magna') {
    return 'https://storage.googleapis.com/onlypays-public/assets/images/magna_icon.png';
  } else if (cardType === 'Redcompra') {
    return 'https://storage.googleapis.com/onlypays-public/assets/images/redcompra_icon.png';
  } else {
    return '';
  }
};

export const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
  hex = hex.replace(/^#/, '');

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return { r, g, b };
};
