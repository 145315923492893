import {
  PaymentPlan,
  ApiList,
  Product,
  Buyer,
  BankInformation,
  ApiObject,
  Statement,
  BillingInformation,
  InvoiceInformation,
} from '../../../app/type';

import client from '../client';

export const list = (
  companyId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<ApiList<PaymentPlan>> => {
  return client({
    method: 'get',
    url: `/v1/payment_plans`,
    params: { company_id: companyId, query, page: page || 1, filter_params: filterParams },
  })
    .then(({ data: body }: { data: ApiList<PaymentPlan> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

interface PaymentPlanData
  extends Partial<
    Omit<PaymentPlan, 'product' | 'buyer' | 'bank_information' | 'billing_information'>
  > {
  extra_fields?: { [key: string]: string };
  product: Partial<Product>;
  buyer: Partial<Buyer>;
  bank_information?: Partial<BankInformation>;
  billing_information?: Partial<BillingInformation>;
  invoice_information?: InvoiceInformation;
}

const processDataForFormData = (data: PaymentPlanData) => {
  const formData = new FormData();
  Object.keys(data).forEach((key: string) => {
    if (key === 'extra_fields') {
      formData.append('payment_plan[extra_fields]', JSON.stringify(data[key]));
    } else if (key === 'product' && data.product) {
      Object.keys(data.product).forEach((productKey: string) => {
        if (productKey === 'extra_fields') {
          formData.append('product[extra_fields]', JSON.stringify(data.product[productKey]));
        } else if (productKey === 'image_file') {
          // @ts-expect-error: ts(7053)
          formData.append('product[image_file]', data.product[productKey], 'image.png');
        } else {
          // @ts-expect-error: ts(7053)
          formData.append(`product[${productKey}]`, data.product[productKey]);
        }
      });
    } else if (key === 'buyer' && data.buyer) {
      Object.keys(data.buyer).forEach((buyerKey: string) => {
        // @ts-expect-error: ts(7053)
        formData.append(`buyer[${buyerKey}]`, data.buyer[buyerKey]);
      });
    } else if (key === 'bank_information' && data.bank_information) {
      Object.keys(data.bank_information).forEach((bankInformationKey: string) => {
        formData.append(
          `bank_information[${bankInformationKey}]`,
          // @ts-expect-error: ts(7053)
          data.bank_information[bankInformationKey]
        );
      });
    } else if (key === 'billing_information' && data.billing_information) {
      Object.keys(data.billing_information).forEach((billingInformationKey: string) => {
        formData.append(
          `billing_information[${billingInformationKey}]`,
          // @ts-expect-error: ts(7053)
          data.billing_information[billingInformationKey]
        );
      });
    } else if (key === 'statements' && data.statements) {
      formData.append('statements', JSON.stringify(data.statements));
    } else if (key === 'invoice_configuration' && data.invoice_configuration) {
      Object.keys(data.invoice_configuration).map((key) => {
        // @ts-expect-error: ts(7053)
        formData.append(`invoice_configuration[${key}]`, data.invoice_configuration[key]);
      });
    } else if (key === 'invoice_information' && data.invoice_information) {
      Object.keys(data.invoice_information).map((key) => {
        // @ts-expect-error: ts(7053)
        formData.append(`invoice_information[${key}]`, data.invoice_information[key]);
      });
    } else {
      // @ts-expect-error: ts(7053)
      formData.append(`payment_plan[${key}]`, data[key]);
    }
  });
  return formData;
};

export const create = (data: PaymentPlanData): Promise<ApiObject<PaymentPlan>> => {
  const formData = processDataForFormData(data);
  return client({
    method: 'post',
    url: `/v1/payment_plans`,
    data: formData,
  })
    .then(({ data: body }: { data: ApiObject<PaymentPlan> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const createAttempt = (data: {
  companyId?: string;
  email?: string;
  name?: string;
}): Promise<{ message: string }> => {
  return client({
    method: 'get',
    url: `/v1/payment_plans/existent_payment_plan`,
    params: { company_id: data.companyId, email: data.email, name: data.name },
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const update = (data: PaymentPlanData): Promise<ApiObject<PaymentPlan>> => {
  const formData = processDataForFormData(data);
  return client({
    method: 'put',
    url: `/v1/payment_plans/${data.id}`,
    data: formData,
  })
    .then(({ data: body }: { data: ApiObject<PaymentPlan> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const show = (paymentPlanId: string): Promise<ApiObject<PaymentPlan>> => {
  return client({
    method: 'get',
    url: `/v1/payment_plans/${paymentPlanId}`,
  })
    .then(({ data: body }: { data: ApiObject<PaymentPlan> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const statements = (paymentPlanId: string, page?: number): Promise<ApiList<Statement>> => {
  return client({
    method: 'get',
    url: `/v1/payment_plans/${paymentPlanId}/statements`,
    params: { page: page || 1 },
  })
    .then(({ data: body }: { data: ApiList<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const showInfoBoxes = (
  paymentPlanId: string
): Promise<ApiObject<{ [key: string]: number }>> => {
  return client({
    method: 'get',
    url: `/v1/payment_plans/${paymentPlanId}/show_boxes_data`,
  })
    .then(({ data: body }: { data: ApiObject<{ [key: string]: number }> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const createStatement = (
  paymentPlanId: string,
  params: { data: Partial<Statement> }
): Promise<ApiObject<Statement>> => {
  return client({
    method: 'post',
    url: `/v1/payment_plans/${paymentPlanId}/statements/create`,
    data: params,
  })
    .then(({ data: body }: { data: ApiObject<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const editStatement = (
  paymentPlanId: string,
  params: Partial<Statement>
): Promise<ApiObject<Statement>> => {
  return client({
    method: 'put',
    url: `/v1/payment_plans/${paymentPlanId}/statements/${params.id}`,
    data: { data: params },
  })
    .then(({ data: body }: { data: ApiObject<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const deleteStatement = (
  paymentPlanId: string,
  statementId: string
): Promise<ApiObject<Statement>> => {
  return client({
    method: 'delete',
    url: `/v1/payment_plans/${paymentPlanId}/statements/${statementId}`,
  })
    .then(({ data: body }: { data: ApiObject<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const deletePaymentPlan = (id: string): Promise<ApiObject<PaymentPlan>> => {
  return client({
    method: 'delete',
    url: `/v1/payment_plans/${id}`,
  })
    .then(({ data: body }: { data: ApiObject<PaymentPlan> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};

export const importPaymentPlans = (file: File): Promise<{ message: string }> => {
  const formData = new FormData();
  formData.append('file', file);
  return client
    .post('/v1/payment_plans/import_payment_plans', formData)
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const generateExcel = (
  parent?: string,
  parentId?: string,
  query?: string,
  filterParams?: { [key: string]: any }
): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: `/v1/payment_plans/generate_excel`,
    params: { parent: parent, parent_id: parentId, query: query, filter_params: filterParams },
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ changeStatusError: error });
      throw error;
    });
};

export const generateStatementsExcel = (parentId?: string): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: `/v1/payment_plans/${parentId}/generate_statements_excel`,
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ changeStatusError: error });
      throw error;
    });
};

export const markAsCanceled = (paymentPlanId: string): Promise<ApiObject<PaymentPlan>> => {
  return client({
    method: 'post',
    url: `/v1/payment_plans/${paymentPlanId}/mark_as_canceled`,
  })
    .then(({ data: body }: { data: ApiObject<PaymentPlan> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const destroy = (paymentPlanId: string): Promise<ApiObject<PaymentPlan>> => {
  return client({
    method: 'delete',
    url: `/v1/payment_plans/${paymentPlanId}`,
  })
    .then(({ data: body }: { data: ApiObject<PaymentPlan> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const deleteInvoiceConfiguration = (
  paymentPlanId: string,
  invoiceConfigurationId: string
): Promise<ApiObject<PaymentPlan>> => {
  return client({
    method: 'delete',
    url: `/v1/payment_plans/${paymentPlanId}/delete_invoice_configuration`,
    params: { invoice_configuration_id: invoiceConfigurationId },
  })
    .then(({ data: body }: { data: ApiObject<PaymentPlan> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export default {
  list,
  create,
  show,
  update,
  statements,
  showInfoBoxes,
  createStatement,
  editStatement,
  deletePaymentPlan,
  createAttempt,
  generateExcel,
  generateStatementsExcel,
  markAsCanceled,
  destroy,
  deleteInvoiceConfiguration,
};
