import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from '../../../app/hooks';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import copyToClipboard from 'copy-text-to-clipboard';

// API
import { sellerApi } from '../../../common/api';

// Features
import { SellerState } from '../sellerSlice';
import { CompanyEmailConfiguration } from '../../../app/type';
import { ApiList, BuyerEmailConfiguration } from '../../../app/type';

// Components
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Skeleton,
  Switch,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { setDocumentTitle } from '../../../common/utils';

// Assets
import cStyles from '../../../common/styles/common.module.scss';
import styles from './CompanySeller.module.scss';

// constants
const max_variable_delta_days = 21;
const min_variable_delta_days = 1;
const default_delta_days = 2;

const EmailsConfiguration = (): React.ReactElement => {
  setDocumentTitle('Configuración de correos');
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [mailDrawerOpen, setMailDrawerOpen] = useState<boolean>(false);
  const [companyEmailConfiguration, setCompanyEmailConfiguration] =
    useState<CompanyEmailConfiguration>({
      charge_subscription_warning: true,
      charge_subscription_warning_delta: default_delta_days,
    });
  const companyColor =
    company?.payment_design?.background_color ||
    'linear-gradient(291.55deg, #ABBFFC 1.03%, #41BBC8 97.27%)';
  const [buyerEmailConfiguartions, setBuyerEmailConfigurations] =
    useState<BuyerEmailConfiguration[]>();
  const today = new Date()
    .toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: '2-digit' })
    .replace(/\//g, '/');

  const getBuyerEmailConfiguration = (email: string) => {
    return buyerEmailConfiguartions?.find((bec: BuyerEmailConfiguration) => bec.email === email);
  };

  const initialValuesChargeSuccess = {
    id: getBuyerEmailConfiguration('charge_success_response')?.id || '',
    email: 'charge_success_response',
    subject: getBuyerEmailConfiguration('charge_success_response')?.subject || '',
    body: getBuyerEmailConfiguration('charge_success_response')?.body || {
      paragraph: '',
    },
  };

  const chargeSuccessFormik = useFormik<BuyerEmailConfiguration>({
    initialValues: initialValuesChargeSuccess,
    onSubmit: (buyerEmailConfiguartion: BuyerEmailConfiguration) => {
      setLoadingSubmit(true);
      sellerApi.buyerEmailConfiguartion
        .update(company?.id || '', buyerEmailConfiguartion)
        .then(() => {
          enqueueSnackbar('Información guardada exitosamente', { variant: 'success' });
        })
        .catch((err: any) => {
          enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          console.error(err);
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    },
    enableReinitialize: true,
  });

  const initialValuesChargeSubscription = {
    id: getBuyerEmailConfiguration('charge_subscription')?.id || '',
    email: 'charge_subscription',
    subject: getBuyerEmailConfiguration('charge_subscription')?.subject || '',
    body: getBuyerEmailConfiguration('charge_subscription')?.body || {
      paragraph: '',
    },
  };

  const chargeSubscriptionFormik = useFormik<BuyerEmailConfiguration>({
    initialValues: initialValuesChargeSubscription,
    onSubmit: (buyerEmailConfiguartion: BuyerEmailConfiguration) => {
      setLoadingSubmit(true);
      sellerApi.buyerEmailConfiguartion
        .update(company?.id || '', buyerEmailConfiguartion)
        .then(() => {
          enqueueSnackbar('Información guardada exitosamente', { variant: 'success' });
        })
        .catch((err: any) => {
          enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          console.error(err);
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    },
    enableReinitialize: true,
  });

  const initialValuesChargeSubscriptionFirstStatement = {
    id: getBuyerEmailConfiguration('charge_subscription_first_statement')?.id || '',
    email: 'charge_subscription_first_statement',
    subject: getBuyerEmailConfiguration('charge_subscription_first_statement')?.subject || '',
    body: getBuyerEmailConfiguration('charge_subscription_first_statement')?.body || {
      paragraph: '',
    },
  };

  const chargeSubscriptionFirstStatementFormik = useFormik<BuyerEmailConfiguration>({
    initialValues: initialValuesChargeSubscriptionFirstStatement,
    onSubmit: (buyerEmailConfiguartion: BuyerEmailConfiguration) => {
      setLoadingSubmit(true);
      sellerApi.buyerEmailConfiguartion
        .update(company?.id || '', buyerEmailConfiguartion)
        .then(() => {
          enqueueSnackbar('Información guardada exitosamente', { variant: 'success' });
        })
        .catch((err: any) => {
          enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          console.error(err);
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    },
    enableReinitialize: true,
  });

  const initialValuesChargeFail = {
    id: getBuyerEmailConfiguration('charge_fail_subscription_response')?.id || '',
    email: 'charge_fail_subscription_response',
    subject: getBuyerEmailConfiguration('charge_fail_subscription_response')?.subject || '',
    body: getBuyerEmailConfiguration('charge_fail_subscription_response')?.body || {
      paragraph: '',
    },
  };

  const chargeFailFormik = useFormik<BuyerEmailConfiguration>({
    initialValues: initialValuesChargeFail,
    onSubmit: (buyerEmailConfiguartion: BuyerEmailConfiguration) => {
      setLoadingSubmit(true);
      sellerApi.buyerEmailConfiguartion
        .update(company?.id || '', buyerEmailConfiguartion)
        .then(() => {
          enqueueSnackbar('Información guardada exitosamente', { variant: 'success' });
        })
        .catch((err: any) => {
          enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          console.error(err);
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    },
    enableReinitialize: true,
  });

  const initialValuesChargeSubscriptionWarning = {
    id: getBuyerEmailConfiguration('charge_subscription_warning')?.id || '',
    email: 'charge_subscription_warning',
    subject: getBuyerEmailConfiguration('charge_subscription_warning')?.subject || '',
    body: getBuyerEmailConfiguration('charge_subscription_warning')?.body || {
      paragraph: '',
    },
  };

  const chargeSubscriptionWarningFormik = useFormik<BuyerEmailConfiguration>({
    initialValues: initialValuesChargeSubscriptionWarning,
    onSubmit: (buyerEmailConfiguartion: BuyerEmailConfiguration) => {
      setLoadingSubmit(true);
      sellerApi.buyerEmailConfiguartion
        .update(company?.id || '', buyerEmailConfiguartion)
        .then(() => {
          enqueueSnackbar('Información guardada exitosamente', { variant: 'success' });
        })
        .catch((err: any) => {
          enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          console.error(err);
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    },
    enableReinitialize: true,
  });

  const onCopy = (text: string) => {
    copyToClipboard(text);
    enqueueSnackbar('Texto copiado', { variant: 'info' });
  };

  const defaultEmails = {
    charge_success_response: {
      subject: '[nombre_producto] | Comprobante de pago',
      body: {},
    },
    charge_subscription: {
      subject: '[nombre_producto] | [nombre_empresa] | Link de pago',
      body: {
        paragraph: 'El pago a realizar por [nombre_producto] es de:',
      },
    },
    charge_subscription_first_statement: {
      subject: '[nombre_producto] | [nombre_empresa] | Link de pago',
      body: {
        paragraph: 'El pago a realizar por [nombre_producto] es de:',
      },
    },
    charge_fail: {
      subject: '[nombre_empresa] | No pudimos cobrarte',
      body: {},
    },
    charge_subscription_warning: {
      subject: '[nombre_producto] | Pronto se realizará un cobro',
      body: {
        paragraph:
          'Pronto te corresponde pagar tu servicio de [nombre_producto]. Te recordamos que elegiste el modo de pago automático, por lo que se te descontará el monto correspondiente a pagar el [fecha_cobro].',
      },
    },
  };

  const closeMailsDrawer = () => {
    setMailDrawerOpen(false);
  };

  const fixSetMailDaysBeforeWarning = (days: number) => {
    if (days < min_variable_delta_days) {
      setCompanyEmailConfiguration({
        ...companyEmailConfiguration,
        charge_subscription_warning_delta: min_variable_delta_days,
      });
      enqueueSnackbar('El número de días tiene que ser positivo', {
        variant: 'error',
      });
    } else if (days > max_variable_delta_days) {
      setCompanyEmailConfiguration({
        ...companyEmailConfiguration,
        charge_subscription_warning_delta: max_variable_delta_days,
      });
      enqueueSnackbar(`El número de días tiene que ser menor a ${max_variable_delta_days}`, {
        variant: 'error',
      });
    } else {
      setCompanyEmailConfiguration({
        ...companyEmailConfiguration,
        charge_subscription_warning_delta: days,
      });
    }
  };

  const setChargeSubscriptionWarning = (value: boolean) => {
    setCompanyEmailConfiguration({
      ...companyEmailConfiguration,
      charge_subscription_warning: value,
    });
  };

  useEffect(() => {
    setLoading(true);
    sellerApi.buyerEmailConfiguartion
      .list(company?.id || '')
      .then((data: ApiList<BuyerEmailConfiguration>) => {
        setBuyerEmailConfigurations(data.data);
      })
      .catch(() => {
        enqueueSnackbar('Ha ocurrido un error, por favor inténtalo nuevamente', {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    sellerApi.companyEmailConfiguration
      .getValues(company?.id || '')
      .then((data: CompanyEmailConfiguration) => {
        setCompanyEmailConfiguration(data);
      })
      .catch(() => {
        enqueueSnackbar('Ha ocurrido un error, por favor inténtalo nuevamente', {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const sendcompanyEmailConfiguration = () => {
    if (
      companyEmailConfiguration.charge_subscription_warning_delta < 1 ||
      isNaN(companyEmailConfiguration.charge_subscription_warning_delta)
    ) {
      setCompanyEmailConfiguration({
        ...companyEmailConfiguration,
        charge_subscription_warning_delta: default_delta_days,
      });
      if (companyEmailConfiguration.charge_subscription_warning) {
        enqueueSnackbar('El número de días tiene que ser positivo', {
          variant: 'error',
        });
        return;
      } else {
        // setState es asincrono así que no se guarda lo suficientemente rápido.
        enqueueSnackbar('Actualizando configuración', {
          variant: 'info',
        });
      }
    }
    setLoading(true);
    sellerApi.companyEmailConfiguration
      .update(company?.id || '', companyEmailConfiguration)
      .then((data: CompanyEmailConfiguration) => {
        setCompanyEmailConfiguration(data);
        enqueueSnackbar('Configuración de correos guardada exitosamente', {
          variant: 'success',
        });
        closeMailsDrawer();
      })
      .catch(() => {
        enqueueSnackbar('Ha ocurrido un error, por favor inténtalo nuevamente', {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <Drawer anchor="right" open={mailDrawerOpen} onClose={() => closeMailsDrawer()}>
        <div className={styles.drawerContainer}>
          <div className={styles.drawerField}>
            <Typography variant="h5" sx={{ margin: '10px 10px', textAlign: 'center' }}>
              Configuración de recordatorio:
            </Typography>
            <Typography variant="h6" sx={{ margin: '0 10px', textAlign: 'center' }}>
              Cobro próximo a la tarjeta inscrita
            </Typography>
            <FormControl>
              <FormGroup>
                <div style={{ margin: '20px 0' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={companyEmailConfiguration.charge_subscription_warning}
                        onChange={(event) => {
                          setChargeSubscriptionWarning(event.target.checked);
                        }}
                        name="send_warning_mail"
                        color="primary"
                      />
                    }
                    label="Envíar correo de recordatorio de cobro próximo a la tarjeta inscrita"
                  ></FormControlLabel>
                </div>
                {companyEmailConfiguration.charge_subscription_warning && (
                  <TextField
                    id="days_before_warning_mail"
                    label="Días antes de la fecha de cobro"
                    variant="outlined"
                    type="number"
                    name="days_before_warning_mail"
                    inputProps={{ min: 1, max: 21 }}
                    value={companyEmailConfiguration.charge_subscription_warning_delta}
                    onChange={(event) => {
                      fixSetMailDaysBeforeWarning(parseInt(event.target.value));
                    }}
                  />
                )}
                <div className={styles.actions} style={{ marginTop: '50px', display: 'flex' }}>
                  <Button variant="outlined" onClick={() => closeMailsDrawer()}>
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      sendcompanyEmailConfiguration();
                    }}
                  >
                    Guardar
                  </Button>
                </div>
              </FormGroup>
            </FormControl>
          </div>
        </div>
      </Drawer>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={styles.infoPaper}>
            <Grid container>
              <Grid item xs={12} className={cStyles.paperHeader}>
                <div className={cStyles.paperHeaderContent}>
                  <Typography variant="h5">Configuración de correos</Typography>
                  <Typography>
                    En esta sección puedes personalizar los correos que se muestran a continuación.
                  </Typography>
                </div>
              </Grid>
              <div className={styles.emailContainer}>
                <Typography variant="h1">Correo de Comprobante de pago</Typography>
                <Typography>
                  Este correo se envía a tus clientes para notificarles que realizaron un pago
                  correctamente.
                </Typography>
                <div className={styles.emailRow}>
                  <div className={styles.inputContainer}>
                    <Typography variant="h6">Asunto del correo</Typography>
                    <Typography fontSize={13} mb={1}>
                      Si quieres incluir el nombre del producto copia y pega esto:{' '}
                      <b onClick={() => onCopy('[nombre_producto]')}>[nombre_producto]</b> en el
                      lugar que quieras que salga el nombre del producto. Si quieres incluir el
                      nombre de tu empresa copia y pega esto:{' '}
                      <b onClick={() => onCopy('[nombre_empresa]')}>[nombre_empresa]</b> en el lugar
                      que quieras que salga el nombre de la empresa. Si quieres incluir el nombre
                      del cliente copia y pega esto:{' '}
                      <b onClick={() => onCopy('[nombre_cliente]')}>[nombre_cliente]</b> en el lugar
                      que quieras que salga el nombre del cliente.
                    </Typography>
                    {loading ? (
                      <Skeleton height={45} />
                    ) : (
                      <TextField
                        placeholder={defaultEmails['charge_success_response']['subject']}
                        id="subject"
                        name="subject"
                        type="text"
                        variant="outlined"
                        value={chargeSuccessFormik.values.subject}
                        onChange={chargeSuccessFormik.handleChange}
                      />
                    )}
                    <Typography variant="h6" mt={2}>
                      Párrafo extra
                    </Typography>
                    {loading ? (
                      <Skeleton height={150} />
                    ) : (
                      <Fragment>
                        <TextField
                          id="body.paragraph"
                          name="body.paragraph"
                          type="text"
                          variant="outlined"
                          value={chargeSuccessFormik.values.body.paragraph}
                          onChange={chargeSuccessFormik.handleChange}
                          multiline
                          rows={5}
                        />
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={loading}
                          className={styles.saveButton}
                          onClick={() => chargeSuccessFormik.submitForm()}
                        >
                          {loadingSubmit ? <CircularProgress size={20} /> : 'Guardar'}
                        </Button>
                      </Fragment>
                    )}
                  </div>
                  <div className={styles.previewContainer}>
                    <div className={styles.subject}>
                      <Typography>
                        <b>Asunto:</b>{' '}
                        {(
                          chargeSuccessFormik.values.subject ||
                          defaultEmails['charge_success_response']['subject']
                        )
                          .replaceAll('[nombre_producto]', 'Producto')
                          .replaceAll('[nombre_empresa]', company?.name || '')
                          .replaceAll('[nombre_cliente]', 'Juan Perez')}
                      </Typography>
                    </div>
                    <div className={styles.topBar} style={{ background: companyColor }}>
                      <img
                        src={
                          company?.image ||
                          'https://storage.googleapis.com/onlypays-public/assets/images/example_store.svg'
                        }
                      />
                    </div>
                    <div className={styles.titleContainer}>
                      <Typography variant="h6">¡Hola Juan Perez!</Typography>
                      <Typography variant="h5">Comprobante de pago</Typography>
                    </div>
                    <Typography variant="h4">Información de pago</Typography>
                    <div className={styles.summaryTable}>
                      <div className={styles.row}>
                        <Typography variant="h6">Nombre servicio:</Typography>
                        <Typography variant="h6">producto x</Typography>
                      </div>
                      <div className={styles.row}>
                        <Typography variant="h6">Monto:</Typography>
                        <Typography>$20.000</Typography>
                      </div>
                      <div className={styles.row}>
                        <Typography variant="h6">Tipo de pago:</Typography>
                        <Typography>Mensual</Typography>
                      </div>
                      <div className={styles.row}>
                        <Typography variant="h6">Fecha de vencimiento:</Typography>
                        <Typography>{today}</Typography>
                      </div>
                      <div className={styles.row}>
                        <Typography variant="h6">Fecha de pago:</Typography>
                        <Typography>{today}</Typography>
                      </div>
                      <div className={styles.row}>
                        <Typography variant="h6">Medio de pago:</Typography>
                        <Typography>Webpay</Typography>
                      </div>
                    </div>
                    <Typography variant="h5">
                      Tu pago ha sido realizado de manera exitosa
                    </Typography>
                    <div className={styles.personalizedParagraph}>
                      <Typography>
                        {chargeSuccessFormik.values.body['paragraph']
                          .replaceAll('[nombre_producto]', 'Producto')
                          .replaceAll('[nombre_empresa]', company?.name || '')
                          .replaceAll('[nombre_cliente]', 'Juan Perez')}
                      </Typography>
                    </div>
                    <div className={styles.checkIcon}>
                      <img src="https://storage.googleapis.com/onlypays-public/assets/images/gold_check.png" />
                    </div>
                    <div className={styles.contact}>
                      <Typography>
                        Recuerda que cualquier duda que tengas, puedes contactarte con:{' '}
                        {company?.email}
                      </Typography>
                    </div>
                    <div className={styles.footer} style={{ background: companyColor }}>
                      <Typography variant="h5">
                        Pago realizado a través de la <b>plataforma de recurrencia</b>:
                      </Typography>
                      <img
                        src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_white.png"
                        alt="Zafepay Logo"
                      />
                      <Typography variant="h6">
                        Zafepay es la plataforma por la que se realiza el pago.{' '}
                        <b>
                          No se hace responsable de entrega de productos, servicios o beneficios de
                          ningún tipo
                        </b>
                        . Cualquier duda de la plataforma, contactar a hola@zafepay.com
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.emailContainer}>
                <Typography variant="h1">
                  Correo de Cobro de la primera cuota de una suscripción
                </Typography>
                <Typography>
                  Este correo se envía a tus clientes para indicarles que deben pagar la primera
                  cuota de una suscrpción. Esto pasa cuando se suscriben a algún servicio y no
                  terminan el pago de forma correcta, por lo que se envía este correo para que
                  finalicen el proceso de suscripción con el pago de la primera cuota.
                </Typography>
                <div className={styles.emailRow}>
                  <div className={styles.inputContainer}>
                    <Typography variant="h6">Asunto del correo</Typography>
                    <Typography fontSize={13} mb={1}>
                      Si quieres incluir el nombre del producto copia y pega esto:{' '}
                      <b onClick={() => onCopy('[nombre_producto]')}>[nombre_producto]</b> en el
                      lugar que quieras que salga el nombre del producto. Si quieres incluir el
                      nombre de tu empresa copia y pega esto:{' '}
                      <b onClick={() => onCopy('[nombre_empresa]')}>[nombre_empresa]</b> en el lugar
                      que quieras que salga el nombre de la empresa. Si quieres incluir el nombre
                      del cliente copia y pega esto:{' '}
                      <b onClick={() => onCopy('[nombre_cliente]')}>[nombre_cliente]</b> en el lugar
                      que quieras que salga el nombre del cliente.
                    </Typography>
                    {loading ? (
                      <Skeleton height={45} />
                    ) : (
                      <TextField
                        placeholder={
                          defaultEmails['charge_subscription_first_statement']['subject']
                        }
                        id="subject"
                        name="subject"
                        type="text"
                        variant="outlined"
                        value={chargeSubscriptionFirstStatementFormik.values.subject}
                        onChange={chargeSubscriptionFirstStatementFormik.handleChange}
                      />
                    )}
                    <Typography variant="h6" mt={2}>
                      Mensaje correo
                    </Typography>
                    {loading ? (
                      <Skeleton height={150} />
                    ) : (
                      <Fragment>
                        <TextField
                          placeholder={
                            defaultEmails['charge_subscription_first_statement']['body'][
                              'paragraph'
                            ]
                          }
                          id="body.paragraph"
                          name="body.paragraph"
                          type="text"
                          variant="outlined"
                          value={chargeSubscriptionFirstStatementFormik.values.body.paragraph}
                          onChange={chargeSubscriptionFirstStatementFormik.handleChange}
                          multiline
                          rows={5}
                        />
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={loading}
                          className={styles.saveButton}
                          onClick={() => chargeSubscriptionFirstStatementFormik.submitForm()}
                        >
                          {loadingSubmit ? <CircularProgress size={20} /> : 'Guardar'}
                        </Button>
                      </Fragment>
                    )}
                  </div>
                  <div className={styles.previewContainer}>
                    <div className={styles.subject}>
                      <Typography>
                        <b>Asunto:</b>{' '}
                        {(
                          chargeSubscriptionFirstStatementFormik.values.subject ||
                          defaultEmails['charge_subscription_first_statement']['subject']
                        )
                          .replaceAll('[nombre_producto]', 'Producto')
                          .replaceAll('[nombre_empresa]', company?.name || '')
                          .replaceAll('[nombre_cliente]', 'Juan Perez')}
                      </Typography>
                    </div>
                    <img
                      className={styles.companyLogo}
                      src={
                        company?.image ||
                        'https://storage.googleapis.com/onlypays-public/assets/images/example_store.svg'
                      }
                    />
                    <div className={styles.titleContainer}>
                      <Typography variant="h6">¡Hola Juan Perez!</Typography>
                    </div>
                    <div className={styles.personalizedParagraph}>
                      <Typography>
                        {(
                          chargeSubscriptionFirstStatementFormik.values.body['paragraph'] ||
                          defaultEmails['charge_subscription_first_statement']['body']['paragraph']
                        )
                          .replaceAll('[nombre_producto]', 'Producto')
                          .replaceAll('[nombre_empresa]', company?.name || '')
                          .replaceAll('[nombre_cliente]', 'Juan Perez')}
                      </Typography>
                    </div>
                    <div className={styles.amount}>
                      <Typography variant="h6">
                        $40.000 <span className={styles.recurrence}>/mensual</span>
                      </Typography>
                    </div>
                    <div className={styles.contact}>
                      <Typography>
                        Haz click en el botón para realizar la transacción. Cualquier duda no
                        olvides contactarte con: <b>{company?.email}</b>
                      </Typography>
                    </div>
                    <div className={styles.payButton}>
                      <Typography>Realizar el pago</Typography>
                    </div>
                    <div className={styles.powered}>
                      <Typography>Powered by </Typography>
                      <img
                        src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_grey.png"
                        alt="Zafepay Logo"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.emailContainer}>
                <Typography variant="h1">
                  Correo de Cobro de cuotas futuras de una suscripción
                </Typography>
                <Typography>
                  Este correo se envía a tus clientes para indicarles que deben pagar alguno de tus
                  servicios a los que están suscritos.
                </Typography>
                <div className={styles.emailRow}>
                  <div className={styles.inputContainer}>
                    <Typography variant="h6">Asunto del correo</Typography>
                    <Typography fontSize={13} mb={1}>
                      Si quieres incluir el nombre del producto copia y pega esto:{' '}
                      <b onClick={() => onCopy('[nombre_producto]')}>[nombre_producto]</b> en el
                      lugar que quieras que salga el nombre del producto. Si quieres incluir el
                      nombre de tu empresa copia y pega esto:{' '}
                      <b onClick={() => onCopy('[nombre_empresa]')}>[nombre_empresa]</b> en el lugar
                      que quieras que salga el nombre de la empresa. Si quieres incluir el nombre
                      del cliente copia y pega esto:{' '}
                      <b onClick={() => onCopy('[nombre_cliente]')}>[nombre_cliente]</b> en el lugar
                      que quieras que salga el nombre del cliente.
                    </Typography>
                    {loading ? (
                      <Skeleton height={45} />
                    ) : (
                      <TextField
                        placeholder={defaultEmails['charge_subscription']['subject']}
                        id="subject"
                        name="subject"
                        type="text"
                        variant="outlined"
                        value={chargeSubscriptionFormik.values.subject}
                        onChange={chargeSubscriptionFormik.handleChange}
                      />
                    )}
                    <Typography variant="h6" mt={2}>
                      Mensaje correo
                    </Typography>
                    {loading ? (
                      <Skeleton height={150} />
                    ) : (
                      <Fragment>
                        <TextField
                          placeholder={defaultEmails['charge_subscription']['body']['paragraph']}
                          id="body.paragraph"
                          name="body.paragraph"
                          type="text"
                          variant="outlined"
                          value={chargeSubscriptionFormik.values.body.paragraph}
                          onChange={chargeSubscriptionFormik.handleChange}
                          multiline
                          rows={5}
                        />
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={loading}
                          className={styles.saveButton}
                          onClick={() => chargeSubscriptionFormik.submitForm()}
                        >
                          {loadingSubmit ? <CircularProgress size={20} /> : 'Guardar'}
                        </Button>
                      </Fragment>
                    )}
                  </div>
                  <div className={styles.previewContainer}>
                    <div className={styles.subject}>
                      <Typography>
                        <b>Asunto:</b>{' '}
                        {(
                          chargeSubscriptionFormik.values.subject ||
                          defaultEmails['charge_subscription']['subject']
                        )
                          .replaceAll('[nombre_producto]', 'Producto')
                          .replaceAll('[nombre_empresa]', company?.name || '')
                          .replaceAll('[nombre_cliente]', 'Juan Perez')}
                      </Typography>
                    </div>
                    <img
                      className={styles.companyLogo}
                      src={
                        company?.image ||
                        'https://storage.googleapis.com/onlypays-public/assets/images/example_store.svg'
                      }
                    />
                    <div className={styles.titleContainer}>
                      <Typography variant="h6">¡Hola Juan Perez!</Typography>
                    </div>
                    <div className={styles.personalizedParagraph}>
                      <Typography>
                        {(
                          chargeSubscriptionFormik.values.body['paragraph'] ||
                          defaultEmails['charge_subscription']['body']['paragraph']
                        )
                          .replaceAll('[nombre_producto]', 'Producto')
                          .replaceAll('[nombre_empresa]', company?.name || '')
                          .replaceAll('[nombre_cliente]', 'Juan Perez')}
                      </Typography>
                    </div>
                    <div className={styles.amount}>
                      <Typography variant="h6">
                        $40.000 <span className={styles.recurrence}>/mensual</span>
                      </Typography>
                    </div>
                    <div className={styles.contact}>
                      <Typography>
                        Haz click en el botón para realizar la transacción. Cualquier duda no
                        olvides contactarte con: <b>{company?.email}</b>
                      </Typography>
                    </div>
                    <div className={styles.payButton}>
                      <Typography>Realizar el pago</Typography>
                    </div>
                    <div className={styles.powered}>
                      <Typography>Powered by </Typography>
                      <img
                        src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_grey.png"
                        alt="Zafepay Logo"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.emailContainer}>
                <Typography variant="h1">
                  Correo de Aviso de fallo de cobro a tarjeta inscrita
                </Typography>
                <Typography>
                  Este correo se envía a tus clientes para advertirles que se intentó realizar un
                  cargo a su tarjeta inscrita sin éxito, y por lo tanto pasan a estar en deuda.
                </Typography>
                <div className={styles.emailRow}>
                  <div className={styles.inputContainer}>
                    <Typography variant="h6">Asunto del correo</Typography>
                    <Typography fontSize={13} mb={1}>
                      Si quieres incluir el nombre del producto copia y pega esto:{' '}
                      <b onClick={() => onCopy('[nombre_producto]')}>[nombre_producto]</b> en el
                      lugar que quieras que salga el nombre del producto. Si quieres incluir el
                      nombre de tu empresa copia y pega esto:{' '}
                      <b onClick={() => onCopy('[nombre_empresa]')}>[nombre_empresa]</b> en el lugar
                      que quieras que salga el nombre de la empresa. Si quieres incluir el nombre
                      del cliente copia y pega esto:{' '}
                      <b onClick={() => onCopy('[nombre_cliente]')}>[nombre_cliente]</b> en el lugar
                      que quieras que salga el nombre del cliente.
                    </Typography>
                    {loading ? (
                      <Skeleton height={45} />
                    ) : (
                      <TextField
                        placeholder={defaultEmails['charge_fail']['subject']}
                        id="subject"
                        name="subject"
                        type="text"
                        variant="outlined"
                        value={chargeFailFormik.values.subject}
                        onChange={chargeFailFormik.handleChange}
                      />
                    )}
                    <Typography variant="h6" mt={2}>
                      Párrafo extra
                    </Typography>
                    {loading ? (
                      <Skeleton height={150} />
                    ) : (
                      <Fragment>
                        <TextField
                          id="body.paragraph"
                          name="body.paragraph"
                          type="text"
                          variant="outlined"
                          value={chargeFailFormik.values.body.paragraph}
                          onChange={chargeFailFormik.handleChange}
                          multiline
                          rows={5}
                        />
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={loading}
                          className={styles.saveButton}
                          onClick={() => chargeFailFormik.submitForm()}
                        >
                          {loadingSubmit ? <CircularProgress size={20} /> : 'Guardar'}
                        </Button>
                      </Fragment>
                    )}
                  </div>
                  <div className={styles.previewContainer}>
                    <div className={styles.subject}>
                      <Typography>
                        <b>Asunto:</b>{' '}
                        {(
                          chargeFailFormik.values.subject || defaultEmails['charge_fail']['subject']
                        )
                          .replaceAll('[nombre_producto]', 'Producto')
                          .replaceAll('[nombre_empresa]', company?.name || '')
                          .replaceAll('[nombre_cliente]', 'Juan Perez')}
                      </Typography>
                    </div>
                    <img
                      className={styles.companyLogo}
                      src={
                        company?.image ||
                        'https://storage.googleapis.com/onlypays-public/assets/images/example_store.svg'
                      }
                    />
                    <div className={styles.titleContainer}>
                      <Typography variant="h6">¡Lo sentimos!</Typography>
                    </div>
                    <div className={styles.messages}>
                      <Typography>
                        El día {today} debías realizar el pago de Producto y falló. Esto puede
                        ocurrir por alguna de las siguientes razones:
                      </Typography>
                      <Typography>
                        1. El banco rechazó tu transacción, por lo que deberías ponerte en contacto
                        con ellos, o<br />
                        2. Tu tarjeta está expirada o no tiene saldo suficiente, por lo que te
                        recomendamos que actualices tus datos en el botón de abajo.
                      </Typography>
                      <Typography>
                        En cualquier caso, te recomendamos actualizar tu tarjeta inscrita y
                        seguiremos intentando cobrarte para no perder tu suscripción.
                      </Typography>
                    </div>
                    <div className={styles.personalizedParagraph}>
                      <Typography sx={{ width: '100%' }}>
                        {chargeFailFormik.values.body['paragraph']
                          .replaceAll('[nombre_producto]', 'Producto')
                          .replaceAll('[nombre_empresa]', company?.name || '')
                          .replaceAll('[nombre_cliente]', 'Juan Perez')}
                      </Typography>
                    </div>
                    <div className={styles.payButton}>
                      <Typography>Configurar método de pago</Typography>
                    </div>
                    <div className={styles.contact}>
                      <Typography>
                        Contacto de {company?.name}: {company?.email}
                      </Typography>
                    </div>

                    <div className={styles.powered}>
                      <Typography>Powered by </Typography>
                      <img
                        src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_grey.png"
                        alt="Zafepay Logo"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.emailContainer}>
                <Typography variant="h1">
                  Correo de recordatorio de cobro próximo a la tarjeta inscrita
                </Typography>
                <Typography>
                  Este correo se envía a tus clientes para avisarles que se va a realizar un cobro a
                  su tarjeta inscrita en los próximos días por alguno de los servicios que está
                  suscrito.
                </Typography>
                <div className={styles.emailRow}>
                  <div className={styles.inputContainer}>
                    <Typography variant="h6">Asunto del correo</Typography>
                    <Typography fontSize={13} mb={1}>
                      Si quieres incluir el nombre del producto copia y pega esto:{' '}
                      <b onClick={() => onCopy('[nombre_producto]')}>[nombre_producto]</b> en el
                      lugar que quieras que salga el nombre del producto. Si quieres incluir el
                      nombre de tu empresa copia y pega esto:{' '}
                      <b onClick={() => onCopy('[nombre_empresa]')}>[nombre_empresa]</b> en el lugar
                      que quieras que salga el nombre de la empresa. Si quieres incluir el nombre
                      del cliente copia y pega esto:{' '}
                      <b onClick={() => onCopy('[nombre_cliente]')}>[nombre_cliente]</b> en el lugar
                      que quieras que salga el nombre del cliente. Si quieres incluir la fecha de
                      cobro copia y pega esto:{' '}
                      <b onClick={() => onCopy('[fecha_cobro]')}>[fecha_cobro]</b> en el lugar que
                      quieras que salga la fecha de cobro.
                    </Typography>
                    {loading ? (
                      <Skeleton height={45} />
                    ) : (
                      <TextField
                        placeholder={defaultEmails['charge_subscription_warning']['subject']}
                        id="subject"
                        name="subject"
                        type="text"
                        variant="outlined"
                        value={chargeSubscriptionWarningFormik.values.subject}
                        onChange={chargeSubscriptionWarningFormik.handleChange}
                      />
                    )}
                    <Typography variant="h6" mt={2}>
                      Mensaje correo
                    </Typography>
                    {loading ? (
                      <Skeleton height={150} />
                    ) : (
                      <Fragment>
                        <TextField
                          placeholder={
                            defaultEmails['charge_subscription_warning']['body']['paragraph']
                          }
                          id="body.paragraph"
                          name="body.paragraph"
                          type="text"
                          variant="outlined"
                          value={chargeSubscriptionWarningFormik.values.body.paragraph}
                          onChange={chargeSubscriptionWarningFormik.handleChange}
                          multiline
                          rows={5}
                        />
                        <Button
                          disableElevation
                          variant="outlined"
                          color="primary"
                          type="submit"
                          disabled={loading}
                          className={styles.saveButton}
                          onClick={() => {
                            setMailDrawerOpen(true);
                          }}
                        >
                          {loadingSubmit ? <CircularProgress size={20} /> : 'Configurar'}
                        </Button>
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={loading}
                          className={styles.saveButton}
                          onClick={() => chargeSubscriptionWarningFormik.submitForm()}
                        >
                          {loadingSubmit ? <CircularProgress size={20} /> : 'Guardar'}
                        </Button>
                      </Fragment>
                    )}
                  </div>
                  <div className={styles.previewContainer}>
                    <div className={styles.subject}>
                      <Typography>
                        <b>Asunto:</b>{' '}
                        {(
                          chargeSubscriptionWarningFormik.values.subject ||
                          defaultEmails['charge_subscription_warning']['subject']
                        )
                          .replaceAll('[nombre_producto]', 'Producto')
                          .replaceAll('[nombre_empresa]', company?.name || '')
                          .replaceAll('[nombre_cliente]', 'Juan Perez')
                          .replaceAll('[fecha_cobro]', '20/12/2024')}
                      </Typography>
                    </div>
                    <img
                      className={styles.companyLogo}
                      src={
                        company?.image ||
                        'https://storage.googleapis.com/onlypays-public/assets/images/example_store.svg'
                      }
                    />
                    <div className={styles.titleContainer}>
                      <Typography variant="h6">¡Hola Juan Perez!</Typography>
                    </div>
                    <div className={styles.messages}>
                      <Typography>
                        {(
                          chargeSubscriptionWarningFormik.values.body['paragraph'] ||
                          defaultEmails['charge_subscription_warning']['body']['paragraph']
                        )
                          .replaceAll('[nombre_producto]', 'Producto')
                          .replaceAll('[nombre_empresa]', company?.name || '')
                          .replaceAll('[nombre_cliente]', 'Juan Perez')
                          .replaceAll('[fecha_cobro]', '20/12/2024')}
                      </Typography>
                    </div>
                    <Typography m="0 auto 0" mt={0} fontSize={11}>
                      El servicio tiene un valor de
                    </Typography>
                    <div className={styles.amount}>
                      <Typography variant="h6">
                        $40.000 <span className={styles.recurrence}>/mensual</span>
                      </Typography>
                    </div>
                    <div className={styles.contact}>
                      <Typography>
                        Cualquier duda excríbenos a {company?.email} o al {company?.phone}.
                      </Typography>
                    </div>
                    <div className={styles.powered}>
                      <Typography>Powered by </Typography>
                      <img
                        src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_grey.png"
                        alt="Zafepay Logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default EmailsConfiguration;
