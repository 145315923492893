import React, { Fragment, useState, useCallback } from 'react';
import { useSelector } from '../../../app/hooks';
import { useFormik, getIn } from 'formik';
import * as yup from 'yup';
import { es } from 'yup-locales';
import {
  Slider,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Paper,
  Container,
  useMediaQuery,
  Autocomplete,
  Checkbox,
  InputAdornment,
} from '@mui/material';

import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import { generateImage } from '../../../common/utils/cropImage';

import styles from './CompanySeller.module.scss';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import variables from '../../../common/styles/variables.module.scss';

yup.setLocale(es);

// Constants
import { BANKS, ACCOUNT_TYPES } from '../../../common/constants/banks';
import { COMMUNES } from '../../../common/constants/communes';
import { BUSINESS_ITEMS } from '../../../common/constants/businessItems';

// Utils
import { validateRut, RUTFormater } from '../../../common/utils';

// Types
import { ApiObject, Company, BankInformation, BillingInformation } from '../../../app/type';

// Features
import { SellerState } from '../sellerSlice';

// Components
import { Grid, Button, TextField, CircularProgress, MenuItem } from '@mui/material';

// API
import { sellerApi } from '../../../common/api';

import { Upload as UploadIcon } from 'react-feather';
import { useSnackbar } from 'notistack';

const CompanySchema = yup.object().shape({
  name: yup.string().max(32).required().label('Nombre de fantasía'),
  email: yup.string().email().label('Correo de contacto'),
  phone: yup
    .string()
    .matches(/^[0-9]+$/, 'Deben ser solo dígitos')
    .min(9, 'Debe tener un largo exacto de 9 dígitos')
    .max(9, 'Debe tener un largo exacto de 9 dígitos')
    .required(),
});

interface CompanyInfo {
  id?: string;
  name: string;
  email: string;
  phone: number;
  image?: string;
  type?: string;
  kind?: string;
  business_item?: string;
  default_billing_information?: Partial<BillingInformation>;
}

interface CompanyFormProps {
  initialData?: CompanyInfo;
  actionLabel?: string;
  onSuccess?: (data: ApiObject<Company>) => void;
  extraActions?: React.ReactElement;
}

export const CompanyForm = (props: CompanyFormProps): React.ReactElement => {
  const { loading } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const { enqueueSnackbar } = useSnackbar();
  const { initialData, actionLabel = 'Guardar', onSuccess, extraActions } = props;
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState<string>('');
  const [croppedArea, setCroppedArea] = React.useState<Area>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const natural = initialData?.kind === 'natural';

  const formik = useFormik<CompanyInfo>({
    initialValues: initialData || {
      name: '',
      email: '',
      image: '',
      kind: '',
      business_item: '',
      phone: 111111111,
      default_billing_information: {
        tax_id: '',
        business_name: '',
        address: '',
        commune: '',
        activity: '',
      },
    },
    validationSchema: CompanySchema,
    onSubmit: (companyInfo: CompanyInfo, { setErrors }: any) => {
      sellerApi.companies
        .create({ data: companyInfo })
        .then(onSuccess)
        .catch((err: any): void => {
          if (err?.response?.status === 400) {
            setErrors(err.response.data?.meta?.errors);
          } else {
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          }
        });
    },
  });
  const [urlImage, setUrlImage] = useState<string>(formik.values.image || '');

  const onCropComplete = useCallback(async (_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const onUploadImage = (event: any) => {
    setDialogOpen(!dialogOpen);
    setImage(URL.createObjectURL(event.target.files[0]));
  };

  const acceptImage = async () => {
    if (croppedArea) {
      const canvas = await generateImage(image, croppedArea);
      canvas
        ? canvas.toBlob(
            (blob: Blob | null) => {
              if (blob) {
                const newImg = document.createElement('img'),
                  url = URL.createObjectURL(blob);

                newImg.onload = () => {
                  URL.revokeObjectURL(url);
                };
                setUrlImage(URL.createObjectURL(blob));
                newImg.src = url;
                formik.setFieldValue('image_file', blob);
                enqueueSnackbar('Imagen procesada con éxito', { variant: 'success' });
              }
            },
            'image/png',
            0.66
          )
        : null;
      setDialogOpen(!dialogOpen);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} className={styles.mainGrid}>
        <Grid item container xs={12} className={styles.formTitle}>
          <Typography variant="h3">Datos sobre {natural ? 'ti:' : 'tu empresa:'}</Typography>
        </Grid>
        {isMobile ? (
          <Grid item className={styles.gridProductImage}>
            <Paper className={styles.paperProductImage}>
              <Container
                id="imageContainer"
                className={styles.containerProductImage}
                sx={{ backgroundImage: `url(${urlImage})` }}
              >
                <Button className={styles.buttonProductImage} component="label">
                  <CameraAltOutlinedIcon sx={{ color: '#FFFFFF' }} />
                  <input type="file" accept="image/*" hidden id="image" onChange={onUploadImage} />
                </Button>
              </Container>
            </Paper>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Paper
              id="imageContainer"
              className={styles.paperImage}
              sx={{ backgroundImage: `url(${urlImage})` }}
            >
              <Fragment></Fragment>
            </Paper>
          </Grid>
        )}
        <Grid item xs={12} sx={{ marginTop: '10px' }}>
          {!natural && (
            <Typography variant="body1">
              *Te recomendamos poner el <strong>logo de tu empresa.</strong>
            </Typography>
          )}
          <Typography variant="body2">
            <i>Puedes utilizar los formatos de png y jpg.</i>
          </Typography>
        </Grid>
        {!isMobile && (
          <Grid item xs={12}>
            <Button variant="contained" component="label">
              Foto de perfil &nbsp; <UploadIcon />{' '}
              <input type="file" accept="image/*" hidden id="image" onChange={onUploadImage} />
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography>Nombre de fantasía*</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="name"
            type="text"
            autoComplete="name"
            variant="outlined"
            value={formik.values.name}
            onChange={(event) => {
              formik.handleChange(event);
              natural && formik.setFieldValue('business_name', event.target.value);
            }}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={
              (formik.touched.name && formik.errors.name) || 'Nombre que verán tus compradores'
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Correo de contacto*</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="email"
            type="text"
            autoComplete="email"
            variant="outlined"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={
              (formik.touched.email && formik.errors.email) || 'Correo que verán tus compradores'
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Teléfono*</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="phone"
            type="text"
            autoComplete="phone"
            variant="outlined"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            InputProps={{
              startAdornment: <InputAdornment position="start">+56</InputAdornment>,
            }}
          />
        </Grid>
        {natural ? null : (
          <Fragment>
            <Grid item xs={12}>
              <Typography>Razón social*</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                id="default_billing_information.business_name"
                type="text"
                autoComplete="default_billing_information.business_name"
                variant="outlined"
                value={formik.values?.default_billing_information?.business_name}
                onChange={formik.handleChange}
                error={
                  getIn(formik.touched, 'default_billing_information.business_name') &&
                  Boolean(getIn(formik.errors, 'default_billing_information.business_name'))
                }
                helperText={
                  (getIn(formik.touched, 'default_billing_information.business_name') &&
                    getIn(formik.errors, 'default_billing_information.business_name')) ||
                  'Nombre al que haremos las facturas'
                }
              />
            </Grid>
          </Fragment>
        )}
        <Grid item xs={12}>
          <Typography>{natural ? 'Tu RUT*' : 'RUT empresa*'}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="default_billing_information.tax_id"
            type="text"
            autoComplete="default_billing_information.tax_id"
            variant="outlined"
            value={formik.values?.default_billing_information?.tax_id}
            onChange={RUTFormater(formik.handleChange)}
            error={
              getIn(formik.touched, 'default_billing_information.tax_id') &&
              Boolean(getIn(formik.errors, 'default_billing_information.tax_id'))
            }
            helperText={
              (getIn(formik.touched, 'default_billing_information.tax_id') &&
                getIn(formik.errors, 'default_billing_information.tax_id')) ||
              ' '
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>{natural ? 'Tu dirección*' : 'Dirección empresa*'}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="default_billing_information.address"
            type="text"
            autoComplete="default_billing_information.address"
            variant="outlined"
            value={formik.values?.default_billing_information?.address}
            onChange={formik.handleChange}
            error={
              getIn(formik.touched, 'default_billing_information.address') &&
              Boolean(getIn(formik.errors, 'default_billing_information.address'))
            }
            helperText={
              (getIn(formik.touched, 'default_billing_information.address') &&
                getIn(formik.errors, 'default_billing_information.address')) ||
              ' '
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Typography>{natural ? 'Tu comuna*' : 'Comuna de la Empresa*'}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            id="default_billing_information.commune"
            options={COMMUNES}
            onChange={(_event, value) =>
              formik.setFieldValue('default_billing_information.commune', value)
            }
            value={formik.values.default_billing_information?.commune}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                error={
                  getIn(formik.touched, 'default_billing_information.commune') &&
                  Boolean(getIn(formik.errors, 'default_billing_information.commune'))
                }
                helperText={
                  (getIn(formik.touched, 'default_billing_information.commune') &&
                    getIn(formik.errors, 'default_billing_information.commune')) ||
                  ' '
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography>
            {natural ? 'Actividad a la que te dedicas*' : 'Giro de la Empresa*'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="default_billing_information.activity"
            type="text"
            autoComplete="default_billing_information.activity"
            variant="outlined"
            value={formik.values?.default_billing_information?.activity}
            onChange={formik.handleChange}
            error={
              getIn(formik.touched, 'default_billing_information.activity') &&
              Boolean(getIn(formik.errors, 'default_billing_information.activity'))
            }
            helperText={
              (getIn(formik.touched, 'default_billing_information.activity') &&
                getIn(formik.errors, 'default_billing_information.activity')) ||
              ' '
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {natural ? '¿Cuál es el rubro del negocio?' : '¿Cuál es el rubro de la empresa?'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            id="business_item"
            options={BUSINESS_ITEMS}
            onChange={(_event, value) => formik.setFieldValue('business_item', value)}
            value={formik.values.business_item}
            renderInput={(params) => (
              <TextField
                {...params}
                error={formik.touched.business_item && Boolean(formik.errors.business_item)}
                helperText={formik.touched.business_item && formik.errors.business_item}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
          {extraActions}
          <Button
            disableElevation
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            className="loader"
          >
            {loading && <CircularProgress size={20} />}
            {actionLabel}
          </Button>
        </Grid>

        <Dialog open={dialogOpen} keepMounted onClose={() => setDialogOpen(!dialogOpen)}>
          <Grid container>
            <Grid item xs={12}>
              <DialogTitle>Ajusta la imagen por favor</DialogTitle>
            </Grid>
            <Grid item xs={12}>
              <DialogContent>
                <div style={{ position: 'relative', width: '100%', height: 250 }}>
                  <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    objectFit="contain"
                  />
                </div>
                <div>
                  <Slider
                    value={zoom}
                    min={1}
                    max={2}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => setZoom(Number(zoom))}
                    classes={{ root: 'slider' }}
                  />
                </div>
              </DialogContent>
            </Grid>
            <Grid item xs={12}>
              <DialogActions>
                <Button onClick={() => setDialogOpen(!dialogOpen)}>Cancelar</Button>
                <Button onClick={acceptImage}>Aceptar</Button>
              </DialogActions>
            </Grid>
          </Grid>
        </Dialog>
      </Grid>
    </form>
  );
};

const BankInformationSchema = yup.object().shape({
  name: yup.string().min(3).max(64).required().label('Nombre'),
  tax_id: yup
    .string()
    .required()
    .required()
    .test('validateRUT', 'RUT inválido.', validateRut)
    .label('RUT'),
  bank: yup.string().required().label('Banco'),
  account_type: yup.string().required().label('Tipo cuenta'),
  account_number: yup.string().required().label('Número cuenta'),
  email: yup.string().email().required().label('Correo'),
});

interface BankInformationInfo {
  id?: string;
  name: string;
  tax_id: string;
  account_number: string;
  bank: string;
  account_type: string;
  email: string;
  company_id: string;
}

interface BankInformationFormProps {
  initialData?: BankInformationInfo;
  actionLabel?: string;
  onSuccess?: (data: ApiObject<BankInformation>) => void;
  extraActions?: React.ReactElement;
  companyId?: string;
  legalCheckbox?: { [key: string]: boolean };
  setLegalCheckbox?: (state: { [key: string]: boolean }) => void;
}

export const BankInformationForm = (props: BankInformationFormProps): React.ReactElement => {
  const { loading } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const { enqueueSnackbar } = useSnackbar();
  const {
    initialData,
    actionLabel = 'Guardar',
    onSuccess = () => null,
    extraActions,
    legalCheckbox,
    setLegalCheckbox,
  } = props;

  const formik = useFormik<BankInformationInfo>({
    initialValues: initialData || {
      name: '',
      tax_id: '',
      bank: '',
      account_type: '',
      account_number: '',
      email: '',
      company_id: props.companyId || '',
    },
    validationSchema: BankInformationSchema,
    onSubmit: (bankInformationInfo: BankInformationInfo, { setErrors }: any) => {
      sellerApi.bankInformations
        .create({ data: bankInformationInfo, set: true })
        .then(onSuccess)
        .catch((err: any): void => {
          if (err?.response?.status === 400) {
            setErrors(err.response.data?.meta?.errors);
          } else {
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          }
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">Datos Bancarios:</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Nombre titular</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="name"
            type="text"
            autoComplete="name"
            variant="outlined"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>RUT titular</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="tax_id"
            type="text"
            autoComplete="tax_id"
            variant="outlined"
            value={formik.values.tax_id}
            onChange={RUTFormater(formik.handleChange)}
            error={formik.touched.tax_id && Boolean(formik.errors.tax_id)}
            helperText={formik.touched.tax_id && formik.errors.tax_id}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Banco</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            select
            id="bank"
            name="bank"
            type="text"
            autoComplete="bank"
            variant="outlined"
            value={formik.values.bank || ''}
            onChange={formik.handleChange}
            error={formik.touched.bank && Boolean(formik.errors.bank)}
            helperText={formik.touched.bank && formik.errors.bank}
          >
            {BANKS.map((bank: string) => (
              <MenuItem key={bank} value={bank}>
                {bank}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography>Tipo cuenta</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            select
            id="account_type"
            name="account_type"
            type="text"
            autoComplete="account_type"
            variant="outlined"
            value={formik.values.account_type || ''}
            onChange={formik.handleChange}
            error={formik.touched.account_type && Boolean(formik.errors.account_type)}
            helperText={formik.touched.account_type && formik.errors.account_type}
          >
            {ACCOUNT_TYPES.map((type: string) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
            {formik.values.bank === 'Banco Estado' && (
              <MenuItem value="Cuenta Vista">Cuenta Rut</MenuItem>
            )}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography>Número de cuenta</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="account_number"
            type="text"
            autoComplete="account_number"
            variant="outlined"
            value={formik.values.account_number}
            onChange={formik.handleChange}
            error={formik.touched.account_number && Boolean(formik.errors.account_number)}
            helperText={formik.touched.account_number && formik.errors.account_number}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Correo titular</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="email"
            type="text"
            autoComplete="email"
            variant="outlined"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>

        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {extraActions}
          {setLegalCheckbox && (
            <>
              <Grid
                item
                xs={5}
                sx={{
                  display: 'flex',
                  minWidth: '100%',
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  onChange={() =>
                    setLegalCheckbox &&
                    setLegalCheckbox({ ...legalCheckbox, ilicitEnd: !legalCheckbox?.['ilicitEnd'] })
                  }
                  style={{ padding: '0px 9px' }}
                />
                <Typography>
                  Declaro que la información entregada es verídica, y no usaré la plataforma para
                  fines ilícitos.
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                sx={{
                  display: 'flex',
                  minWidth: '100%',
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  onChange={() =>
                    setLegalCheckbox &&
                    setLegalCheckbox({ ...legalCheckbox, tyc: !legalCheckbox?.['tyc'] })
                  }
                  style={{ padding: '0px 9px' }}
                />
                <Typography>
                  He leído los{' '}
                  <a href="https://www.zafepay.com/t%C3%A9rmino-y-condiciones">
                    términos y condiciones
                  </a>
                </Typography>
              </Grid>
            </>
          )}
          <Button
            fullWidth
            disableElevation
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading || !legalCheckbox?.['ilicitEnd'] || !legalCheckbox?.['tyc']}
            className="loader"
          >
            {loading && <CircularProgress size={20} />}
            {actionLabel}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
