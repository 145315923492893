import React, { Fragment, useEffect, useState } from 'react';
import { Redirect, Route, Switch, Link, useLocation, useHistory } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  useMediaQuery,
  Grid,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
} from '@mui/material';

import ProductIndex from './products/Index';

import PaymentPlanShow from './products/PaymentPlanShow';
import SubscriptionShow from './products/SubscriptionShow';

import Cards from './Cards';
import Avatar from '../../common/components/Avatar';
import { ClientState } from './clientSlice';
import { useSelector } from '../../app/hooks';
import { clientApi } from '../../common/api';
import { useQuery, navigateTop } from '../../common/utils';
import NotificationBox from '../../common/components/Notification';
import CardsPopup from './components/CardsPopup';
import { useNotifyContext } from '../../common/contexts/Notify';
import Payments from './Payments';

import {
  ApiList,
  Card,
  PAC,
  PaymentPlan,
  SinglePaymentBuyer,
  SubscriptionBuyer,
} from '../../app/type';
import { ClientError } from './Responses';

import styles from './Client.module.scss';
import variables from '../../common/styles/variables.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faBox, faFolder } from '@fortawesome/free-solid-svg-icons';
import CardSuccess from './CardSuccess';
import ShopCartProvider from '../../common/contexts/ShopCart';
import { useSnackbar } from 'notistack';

const ClientComponent = (): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentPlans, setPaymentPlans] = useState<PaymentPlan[]>();
  const [subscriptionBuyers, setSubscriptionBuyers] = useState<SubscriptionBuyer[]>();
  const [singlePaymentBuyers, setSinglePayments] = useState<SinglePaymentBuyer[]>();
  const [selectedView, setSelectedView] = useState<number>(0);
  const [rejectedCards, setRejectedCards] = useState<string[]>([]);
  const [cards, setCards] = useState<Card[]>([]);
  const [PACs, setPACs] = useState<PAC[]>([]);

  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { notifications, removeNotification, addNotification } = useNotifyContext();
  const [notificationsToPaymentPlan, setNotificationsToPaymentPlan] = useState<{
    [key: number]: string;
  }>({});
  const query = useQuery();
  const history = useHistory();
  const { client } = useSelector(({ client }: { client: ClientState }) => client);
  const { company } = useSelector(({ client }: { client: ClientState }) => client);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [selectedNotificationPaymentPlan, setSelectedNotificationPaymentPlan] =
    useState<PaymentPlan>();

  const RouteListItem = (props: {
    label: string;
    route: string;
    icon: React.ReactElement;
  }): React.ReactElement => {
    const selected = pathname.includes(`/client/${props.route}`);

    return (
      <ListItem
        button
        key={props.route}
        component={Link}
        to={`/client/${props.route}`}
        selected={selected}
        style={{
          opacity: selected ? '1' : '0.4',
          backgroundColor: selected ? 'rgba(0, 0, 0, 0.2)' : 'transparent',
        }}
      >
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.label} />
      </ListItem>
    );
  };

  const loadProducts = (client_id: string) => {
    setLoading(true);
    const queries: Promise<any>[] = [new Promise((resolve) => setTimeout(resolve, 1000))];
    const paymentPlansQuery = clientApi.paymentPlans
      .list(client_id)
      .then((data: ApiList<PaymentPlan>) => {
        setPaymentPlans(data.data);
      })
      .catch(() => {
        history.replace('/client/error');
      });
    const subscriptionBuyersQuery = clientApi.subscriptionBuyers
      .list(client_id)
      .then((data: ApiList<SubscriptionBuyer>) => {
        setSubscriptionBuyers(data.data);
      })
      .catch(() => {
        history.replace('/client/error');
      });
    const singlePaymentBuyersQuery = clientApi.singlePaymentBuyers
      .list(client_id)
      .then((data: ApiList<SinglePaymentBuyer>) => {
        setSinglePayments(data.data);
      })
      .catch(() => {
        history.replace('/client/error');
      });
    const cardsQuery = clientApi.webpayCards
      .list(client?.id || '')
      .then((data: ApiList<Card>) => {
        const cards = data.data;
        cards.forEach((card: Card) => {
          if (card.transaction_rejected) setRejectedCards([...rejectedCards, card.id]);
        });
      })
      .catch(console.error);
    queries.push(paymentPlansQuery);
    queries.push(subscriptionBuyersQuery);
    queries.push(singlePaymentBuyersQuery);
    queries.push(cardsQuery);
    Promise.all(queries).finally(() => {
      setLoading(false);
    });
  };

  const handleMobileNavbar = (tab: number) => {
    if (tab === 2) {
      history.replace('/client/cards');
      setSelectedView(2);
    } else if (tab === 1) {
      history.replace('/client/payments');
      setSelectedView(1);
    } else {
      history.replace('/client/products');
      setSelectedView(0);
    }
    navigateTop();
  };

  const redirectToPaymentMethod = (id: number) => {
    const paymentPlan = paymentPlans?.find(
      (element) => element.id === notificationsToPaymentPlan[id]
    );
    setSelectedNotificationPaymentPlan(paymentPlan);
    setOpenPopup(true);
  };

  const onClickNotificationRender = (id: number) => {
    if (notificationsToPaymentPlan[id] === 'card_error') {
      history.push('/client/cards');
    } else {
      redirectToPaymentMethod(id);
    }
  };

  useEffect(() => {
    setLoading(true);
    loadProducts(client?.id || '');
    setSelectedView(pathname.includes('products') ? 0 : pathname.includes('payments') ? 1 : 2);
  }, []);

  useEffect(() => {
    if (paymentPlans) {
      paymentPlans.forEach((pp: PaymentPlan) => {
        if ((pp.card || pp.pac) == undefined || null || '') {
          const notificationId = addNotification(
            <span>
              Debes asignar una tarjeta a tu plan de pago <b>{pp.product.name}</b>.
            </span>,
            {
              variant: 'info',
            }
          );
          setNotificationsToPaymentPlan({ ...notificationsToPaymentPlan, [notificationId]: pp.id });
        }
        if (pp.card && pp.expired_statements_ids.length > 0 && rejectedCards.includes(pp.card.id)) {
          const notificationId = addNotification(
            <span>
              No pudimos cobrar el plan de pago <b>{pp.product.name}</b>. Arregla tu tarjeta aquí.
            </span>,
            {
              variant: 'error',
            }
          );
          setNotificationsToPaymentPlan({
            ...notificationsToPaymentPlan,
            [notificationId]: 'card_error',
          });
        }
      });
    }
  }, [paymentPlans]);

  useEffect(() => {
    if ((query.new_assignment || query.payment_success) && client) {
      setLoading(true);
      const queries: Promise<any>[] = [new Promise((resolve) => setTimeout(resolve, 1000))];
      const reloadPaymentPlansQuery = clientApi.paymentPlans
        .list(client.id)
        .then((data: ApiList<PaymentPlan>) => {
          setPaymentPlans(data.data);
        })
        .catch(() => {
          history.replace('/client/error');
        });
      const reloadSubscriptionBuyersQuery = clientApi.subscriptionBuyers
        .list(client.id)
        .then((data: ApiList<SubscriptionBuyer>) => {
          setSubscriptionBuyers(data.data);
        })
        .catch(() => {
          history.replace('/client/error');
        });
      const reloadSinglePaymentBuyersQuery = clientApi.singlePaymentBuyers
        .list(client.id)
        .then((data: ApiList<SinglePaymentBuyer>) => {
          setSinglePayments(data.data);
        })
        .catch(() => {
          history.replace('/client/error');
        });
      queries.push(reloadPaymentPlansQuery);
      queries.push(reloadSubscriptionBuyersQuery);
      queries.push(reloadSinglePaymentBuyersQuery);
      Promise.all(queries).finally(() => {
        setLoading(false);
        if (query.show) {
          history.replace(`/client/products/${query.show}`);
        } else if (query.payment_success) {
          history.replace('/client/payments');
          enqueueSnackbar('Pago exitoso, te llegará un correo con el comprobante', {
            variant: 'success',
          });
        } else {
          history.replace('/client/products');
        }
      });
    } else if (query.payment_error) {
      history.replace('/client/products');
      enqueueSnackbar('Ocurrió un error al procesar el pago', { variant: 'error' });
    } else if (query.payment_pending) {
      history.replace('/client/payments');
      enqueueSnackbar('El pago está siendo procesado, te avisaremos cuando sea resuelto', {
        variant: 'success',
      });
    }
  }, [query]);

  const loadCardsAndPacs = () => {
    let isMounted = true;

    setLoading(true);
    const queries: Promise<any>[] = [new Promise((resolve) => setTimeout(resolve, 2000))];
    const webpayCardsQuery = clientApi.webpayCards
      .list(client?.id || '')
      .then((data: ApiList<Card>) => {
        if (isMounted) {
          setCards(data.data);
        }
      })
      .catch(console.error);
    const kushkiCardsQuery = clientApi.kushkiCards
      .list(client?.id || '')
      .then((data: ApiList<Card>) => {
        if (isMounted) {
          setCards((prevCards) => [...prevCards, ...data.data]);
        }
      })
      .catch(console.error);
    const loadFintocPACs = clientApi.fintocSubscriptions
      .list(client?.id || '', { status: 'active' })
      .then((data) => {
        if (isMounted) {
          setPACs(data.data);
        }
      })
      .catch(console.error);
    // const loadKhipuPACs = clientApi.khipuSubscriptions
    //   .list(client?.id || '')
    //   .then((data: ApiList<PAC>) => {
    //     if (isMounted) {
    //       setPACs((prevPACS) => [...prevPACS, ...data.data]);
    //     }
    //   })
    //   .catch(console.error);
    queries.push(webpayCardsQuery);
    queries.push(loadFintocPACs);
    // queries.push(loadKhipuPACs);
    queries.push(kushkiCardsQuery);
    Promise.all(queries).finally(() => {
      if (isMounted) {
        setLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  };

  useEffect(() => {
    loadCardsAndPacs();
    if (company?.subdomain) {
      const subdomain = document.location.host.split('.')[0];
      if (subdomain !== 'app' && subdomain !== company?.subdomain) history.replace('/client/error');
    }
  }, []);

  return (
    <>
      <ShopCartProvider>
        <div
          className={styles.appContainer}
          style={{
            background: company?.payment_design?.background_color
              ? company?.payment_design?.background_color
              : '#4653e3',
          }}
        >
          {loading && (
            <div className={styles.loaderContainer}>
              <img
                src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
                alt="Logo Zafepay"
              />
            </div>
          )}
          <Drawer
            open={!isMobile}
            sx={{
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
              },
            }}
            className={styles.drawer}
            variant={isMobile ? 'temporary' : 'persistent'}
            anchor="left"
          >
            <div>
              <Grid container>
                <Grid item container xs={12}>
                  <Grid item xs={12} display="flex" justifyContent="center">
                    <Avatar
                      className={styles.avatar}
                      img={company?.image || undefined}
                      context="company"
                    />
                  </Grid>
                  <Grid item xs={12} textAlign="center">
                    <Fragment>
                      <div className={styles.companyName}>
                        <Typography variant="h4" fontWeight={500}>
                          {company?.name}
                        </Typography>
                      </div>
                    </Fragment>
                  </Grid>
                </Grid>
              </Grid>
              <List className={styles.listContainer}>
                <RouteListItem
                  label="Productos"
                  route="products"
                  icon={<FontAwesomeIcon icon={faBox} />}
                />
                <RouteListItem
                  label="Historial de pagos"
                  route="payments"
                  icon={<FontAwesomeIcon icon={faFolder} />}
                />
                {company?.automatic_payment_methods && (
                  <RouteListItem
                    label="Medios de pago"
                    route="cards"
                    icon={<FontAwesomeIcon icon={faCreditCard} />}
                  />
                )}
              </List>
            </div>
            <div className={styles.logoOp}>
              <img
                src={
                  'https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_white.png'
                }
                alt="Logo Zafepay"
              />
            </div>
          </Drawer>
          <div className={styles.mainContainer}>
            {notifications.map((notif) => (
              <NotificationBox
                key={notif.id}
                notification={notif}
                onRemove={removeNotification}
                clientModule={true}
                onNotificationClick={onClickNotificationRender}
              />
            ))}
            {isMobile && (
              <div className={styles.mobileHeader}>
                <Avatar
                  className={styles.avatar}
                  img={company?.image || undefined}
                  context="company"
                />
                <Typography>{company?.name}</Typography>
              </div>
            )}
            <Fragment>
              <Switch>
                <Route path="/client/products/payment_plan/:paymentPlanId">
                  <PaymentPlanShow cards={cards} PACs={PACs} />
                </Route>
                <Route path="/client/products/recurrent/:subscriptionBuyerId">
                  <SubscriptionShow cards={cards} PACs={PACs} />
                </Route>
                <Route path="/client/products">
                  <ProductIndex
                    paymentPlans={paymentPlans || []}
                    subscriptionBuyers={subscriptionBuyers || []}
                    singlePaymentBuyers={singlePaymentBuyers || []}
                    cards={cards}
                    PACs={PACs}
                  />
                </Route>
                <Route path="/client/payments">
                  <Payments />
                </Route>
                <Route path="/client/cards">
                  <Cards />
                </Route>
                <Route path="/client/error">
                  <ClientError />
                </Route>
                <Route path="/client/card_success">
                  <CardSuccess loadCardsAndPacs={loadCardsAndPacs} />
                </Route>
                <Route path="/client/*">
                  <Redirect to="/client/products" />
                </Route>
              </Switch>
            </Fragment>
            {isMobile && (
              <div className={styles.mobileNavbar}>
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                  <BottomNavigation
                    showLabels
                    value={selectedView}
                    onChange={(_, value) => {
                      handleMobileNavbar(value);
                    }}
                  >
                    <BottomNavigationAction
                      label="Productos"
                      icon={<FontAwesomeIcon icon={faBox} />}
                      style={{
                        color:
                          selectedView === 0
                            ? company?.payment_design?.background_color
                              ? company?.payment_design?.background_color
                              : '#4653e3'
                            : '#707070',
                      }}
                    />
                    <BottomNavigationAction
                      label="Pagos"
                      icon={<FontAwesomeIcon icon={faFolder} />}
                      style={{
                        color:
                          selectedView === 1
                            ? company?.payment_design?.background_color
                              ? company?.payment_design?.background_color
                              : '#4653e3'
                            : '#707070',
                      }}
                    />
                    {company?.automatic_payment_methods && (
                      <BottomNavigationAction
                        label="Medios de pago"
                        icon={<FontAwesomeIcon icon={faCreditCard} />}
                        style={{
                          color:
                            selectedView === 2
                              ? company?.payment_design?.background_color
                                ? company?.payment_design?.background_color
                                : '#4653e3'
                              : '#707070',
                        }}
                      />
                    )}
                  </BottomNavigation>
                </Paper>
              </div>
            )}
          </div>
        </div>
        <CardsPopup
          state={{ open: openPopup, setOpen: setOpenPopup }}
          projectName={selectedNotificationPaymentPlan?.product.name || ''}
          card={selectedNotificationPaymentPlan?.card?.id || ''}
          productId={selectedNotificationPaymentPlan?.id || ''}
          isPaymentPlan={true}
          cards={cards}
          PACs={PACs}
        />
      </ShopCartProvider>
    </>
  );
};

export default ClientComponent;
