import { ApiObject, PaymentTransaction } from '../../../app/type';

import client from '../client';

export const show = (paymentId: string): Promise<ApiObject<PaymentTransaction>> => {
  return client({
    method: 'get',
    url: `/v1/public/payment_transactions/${paymentId}`,
  })
    .then(({ data }: { data: ApiObject<PaymentTransaction> }) => {
      return data;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    });
};
