import { ApiObject, SubscriptionBuyer, Email, ApiList, Statement, Note } from '../../../app/type';

import client from '../client';

export const destroySubscriptionBuyer = (
  subscriptionBuyerId: string
): Promise<ApiObject<SubscriptionBuyer>> => {
  return client({
    method: 'delete',
    url: `/v1/subscription_buyers/${subscriptionBuyerId}`,
  })
    .then(({ data: body }: { data: ApiObject<SubscriptionBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const markAsActive = (
  subscriptionBuyerId: string,
  dueDate?: string,
  updateDop?: boolean
): Promise<ApiObject<SubscriptionBuyer>> => {
  return client({
    method: 'post',
    url: `/v1/subscription_buyers/${subscriptionBuyerId}/mark_as_active`,
    data: { due_date: dueDate, update_dop: updateDop },
  })
    .then(({ data: body }: { data: ApiObject<SubscriptionBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const checkChargeEmail = (subscriptionBuyerId: string): Promise<Email> => {
  return client({
    method: 'get',
    url: `/v1/subscription_buyers/${subscriptionBuyerId}/check_charge_email`,
  })
    .then(({ data: body }: { data: Email }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const sendChargeEmail = (
  subscriptionBuyerId: string
): Promise<ApiObject<SubscriptionBuyer>> => {
  return client({
    method: 'post',
    url: `/v1/subscription_buyers/${subscriptionBuyerId}/send_charge_email`,
  })
    .then(({ data: body }: { data: ApiObject<SubscriptionBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const markAsCanceled = (
  subscriptionBuyerId: string
): Promise<ApiObject<SubscriptionBuyer>> => {
  return client({
    method: 'post',
    url: `/v1/subscription_buyers/${subscriptionBuyerId}/mark_as_canceled`,
  })
    .then(({ data: body }: { data: ApiObject<SubscriptionBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const unsubscribeCard = (
  subscriptionBuyerId: string
): Promise<ApiObject<SubscriptionBuyer>> => {
  return client({
    method: 'put',
    url: `/v1/subscription_buyers/${subscriptionBuyerId}/unsubscribe_card`,
  })
    .then(({ data: body }: { data: ApiObject<SubscriptionBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ unsubscribeError: error });
      throw error;
    });
};

export const markAsCanceledPublic = (
  subscriptionBuyerId: string
): Promise<ApiObject<SubscriptionBuyer>> => {
  return client({
    method: 'post',
    url: `/v1/public/subscription_buyers/${subscriptionBuyerId}/mark_as_canceled_public`,
  })
    .then(({ data: body }: { data: ApiObject<SubscriptionBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const statements = (
  subscriptionBuyerId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<ApiList<Statement>> => {
  return client({
    method: 'get',
    url: `/v1/subscription_buyers/${subscriptionBuyerId}/statements`,
    params: { query, page: page, filter_params: filterParams || {} },
  })
    .then(({ data: body }: { data: ApiList<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

export const markAsPaused = (
  subscriptionBuyerId: string
): Promise<ApiObject<SubscriptionBuyer>> => {
  return client({
    method: 'post',
    url: `/v1/subscription_buyers/${subscriptionBuyerId}/mark_as_paused`,
  })
    .then(({ data: body }: { data: ApiObject<SubscriptionBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const notes = (subscriptionBuyerId: string): Promise<ApiList<Note>> => {
  return client({
    method: 'get',
    url: `/v1/subscription_buyers/${subscriptionBuyerId}/notes`,
  })
    .then(({ data: body }: { data: ApiList<Note> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const addNote = (
  subscriptionBuyerId: string,
  description: string
): Promise<ApiList<Note>> => {
  return client({
    method: 'post',
    url: `/v1/subscription_buyers/${subscriptionBuyerId}/add_note`,
    params: { description: description },
  })
    .then(({ data: body }: { data: ApiList<Note> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const deleteNote = (subscriptionBuyerId: string, noteId: string): Promise<ApiList<Note>> => {
  return client({
    method: 'delete',
    url: `/v1/subscription_buyers/${subscriptionBuyerId}/delete_note`,
    params: { note_id: noteId },
  })
    .then(({ data: body }: { data: ApiList<Note> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};
