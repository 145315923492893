import React, { Fragment, useEffect, useState, useRef } from 'react';
import { SellerState, setPaymentPlan } from '../sellerSlice';
import { useHistory } from 'react-router-dom';
import { PaymentPlan } from '../../../app/type';
import { useSnackbar } from 'notistack';

import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { useSelector } from '../../../app/hooks';
import ResourceList from '../../../common/components/ResourceList';
import InfoBox from '../../../common/components/InfoBox';

import { sellerApi } from '../../../common/api';
import { setDocumentTitle } from '../../../common/utils';
import variables from '../../../common/styles/variables.module.scss';

import styles from './Index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faListUl,
  faDownload,
  faUpload,
  faEye,
  faMinusSquare,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import StatusLabel from '../../../common/components/StatusLabel';
import PopUp from '../../../common/components/PopUp';

const Index = (): React.ReactElement => {
  setDocumentTitle('Planes de Pago');
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const history = useHistory();
  const [importOpen, openImport] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelPopup, setOpenCancelPopup] = useState<boolean>(false);
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
  const [paymentPlanState, setPaymentPlanState] = useState<PaymentPlan>();
  const reloadRef = useRef<{ reloadResource: () => void }>();

  const createPaymentPlan = () => {
    history.push('payment_plans/new');
  };

  const selectItem = (id: string) => {
    history.push(`payment_plans/${id}`);
  };

  const downloadTemplate = () => {
    window.open(
      'https://storage.googleapis.com/onlypays-public/assets/files/Template%20Planes%20de%20pago.xlsx',
      '_blank',
      'noreferrer'
    );
  };

  const handleFileChange = (event: any) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const boxes = (width?: number): React.ReactElement[] => [
    <InfoBox
      key={1}
      title="Crear Plan de Pago"
      function={createPaymentPlan}
      kind="link"
      image="https://storage.cloud.google.com/onlypays-public/assets/images/young%20man%20working%20at%20desk.png"
      style={styles.firstLinkContainer}
      width={width}
    />,
    <InfoBox
      key={2}
      title="Importar desde excel"
      function={() => openImport(true)}
      kind="link"
      image="https://storage.googleapis.com/onlypays-public/assets/images/girl%20and%20boy%20working%20together%20in%20front%20of%20laptop.svg"
      style={styles.secondLinkContainer}
      width={width}
    />,
  ];

  const renderImportContent = (
    <div className={styles.excelImportContainer}>
      <Button variant="outlined" className={styles.excelImportButton} onClick={downloadTemplate}>
        <Typography variant="h5">
          <FontAwesomeIcon icon={faDownload} /> Descargar template
        </Typography>
      </Button>
      <Button variant="outlined" component="label" className={styles.excelImportButton}>
        <Typography variant="h5">
          <FontAwesomeIcon icon={faUpload} /> Cargar archivo
        </Typography>
        <input
          type="file"
          hidden
          onChange={handleFileChange}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
      </Button>
      <Typography variant="h6" sx={{ marginRight: '20px' }}>
        {file?.name}
      </Typography>
    </div>
  );

  const uploadFile = () => {
    if (file) {
      sellerApi.paymentPlans
        .importPaymentPlans(file)
        .then(() => {
          enqueueSnackbar(
            'Recibimos la información, te enviaremos un correo cuando la hayamos procesado',
            { variant: 'info' }
          );
        })
        .catch(() => {
          enqueueSnackbar('Ha ocurrido un error', { variant: 'error' });
        })
        .finally(() => {
          openImport(false);
        });
    }
  };

  const markPaymentPlanAsCanceledAction = (paymentPlan: PaymentPlan) => {
    setOpenCancelPopup(true);
    setPaymentPlanState(paymentPlan);
  };

  const markPaymentPlanAsCanceled = () => {
    if (!paymentPlanState?.id) return;
    setOpenCancelPopup(false);
    sellerApi.paymentPlans
      .markAsCanceled(paymentPlanState.id)
      .then(() => {
        enqueueSnackbar('Se canceló el plan de pago', { variant: 'info' });
        reloadRef?.current?.reloadResource?.();
      })
      .catch(() => enqueueSnackbar('No se pudo cancelar el plan de pago', { variant: 'error' }))
      .finally(() => {
        setPaymentPlanState(undefined);
      });
  };

  const deletePaymentPlanAction = (paymentPlan: PaymentPlan) => {
    setOpenDeletePopup(true);
    setPaymentPlanState(paymentPlan);
  };

  const deletePaymentPlan = () => {
    if (!paymentPlanState?.id) return;
    sellerApi.paymentPlans
      .destroy(paymentPlanState.id)
      .then(() => {
        enqueueSnackbar('Plan de pago eliminado exitosamente', { variant: 'success' });
        reloadRef?.current?.reloadResource?.();
      })
      .catch((error: any) => {
        let msg = 'No se pudo eliminar el plan de pago';
        if (error.response.data?.message == 'has_payments')
          msg = 'No se puede eliminar porque tiene cuotas pagadas, puedes cancelar el plan de pago';
        enqueueSnackbar(msg, { variant: 'error' });
      })
      .finally(() => {
        setPaymentPlanState(undefined);
      });
  };

  useEffect(() => {
    if (importOpen === false) {
      setFile(undefined);
    }
  }, [importOpen]);

  return (
    <>
      <div className={styles.start}>
        <Typography variant={isMobile ? 'h5' : 'h4'}>
          <FontAwesomeIcon icon={faListUl} />
          <span className={styles.startTitle}>Planes de pago</span>
        </Typography>
      </div>
      {isMobile ? (
        <Button className={styles.toButton} onClick={createPaymentPlan} variant="contained">
          Crear Plan de Pago
        </Button>
      ) : (
        <Grid container className={styles.containerGrid} flexWrap={'nowrap'}>
          {boxes(4).map((box) => (
            <Fragment key={box.key}>{box}</Fragment>
          ))}
        </Grid>
      )}
      <div>
        <ResourceList
          title="Planes de pago"
          queryFields=""
          resourceParent={company}
          setResource={setPaymentPlan}
          excelDownloadMethod={(query, parentId, filterParams) =>
            sellerApi.paymentPlans.generateExcel('company', parentId, query, filterParams)
          }
          getResourceList={sellerApi.paymentPlans.list}
          innerRef={reloadRef}
          listHeaders={[
            { key: 'name', label: 'Nombre' },
            { key: 'buyerName', label: 'Nombre cliente' },
            { key: 'status', label: 'Estado' },
            { key: 'installments', label: 'Cuotas pagadas/Total' },
            { key: 'collection_company', label: 'Proyecto' },
          ]}
          listColumns={{
            name: (res) => res.product.name,
            buyerName: (res) => res.buyer.name,
            status: (res) => <StatusLabel status={res.status} type="paymentPlan" />,
            installments: (res) => `${res.total_paid_installments}/${res.total_installments}`,
            collection_company: (res) => res.collection_company_name,
          }}
          listActionsHeaders={(res) => {
            if (res) {
              return [
                { key: 'show', label: 'Ver detalle', icon: faEye },
                res.status === 'canceled'
                  ? null
                  : company?.hide_payment_plan_edition
                  ? null
                  : {
                      key: 'cancel',
                      label: 'Cancelar Plan de pago',
                      icon: faMinusSquare,
                      color: 'error',
                    },
                company?.hide_payment_plan_edition
                  ? null
                  : {
                      key: 'destroy',
                      label: 'Eliminar Plan de pago',
                      icon: faTrashCan,
                      color: 'error',
                    },
              ];
            }
            return [null];
          }}
          listActions={{
            show: (res: PaymentPlan) => selectItem(res.id),
            cancel: (res: PaymentPlan) => markPaymentPlanAsCanceledAction(res),
            destroy: (res: PaymentPlan) => deletePaymentPlanAction(res),
          }}
          listMobileHeaders={[
            { key: 'name' },
            { key: 'buyerName' },
            { key: 'status' },
            { key: 'installments' },
          ]}
        />
      </div>
      <PopUp
        state={{ open: importOpen, setOpen: openImport }}
        content={renderImportContent}
        extraActions={[
          <Button key={1} onClick={uploadFile} variant="contained">
            Guardar
          </Button>,
          <Button key={2} onClick={() => openImport(false)} variant="outlined">
            Cancelar
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: openCancelPopup, setOpen: setOpenCancelPopup }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Al cancelar el plan de pago se eliminarán todas las cuotas que no se han pagado y no se
            cobrará más. Esta acción es irreversible. ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={() => setOpenCancelPopup(false)} variant="outlined" key={2}>
            No
          </Button>,
          <Button onClick={markPaymentPlanAsCanceled} color="error" variant="contained" key={1}>
            Si
          </Button>,
        ]}
      />
      <PopUp
        state={{ open: openDeletePopup, setOpen: setOpenDeletePopup }}
        title={
          <Typography variant="h6" align="center">
            Advertencia
          </Typography>
        }
        content={
          <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
            Al eliminar el plan de pago se eliminarán todas las cuotas que no se han pagado y se
            borrará el registro de este. Esta acción es irreversible. ¿Quieres continuar?
          </Typography>
        }
        extraActions={[
          <Button onClick={() => setOpenDeletePopup(false)} variant="outlined" key={2}>
            No
          </Button>,
          <Button onClick={deletePaymentPlan} color="error" variant="contained" key={1}>
            Si
          </Button>,
        ]}
      />
    </>
  );
};

export default Index;
