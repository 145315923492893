import React, { Fragment, MutableRefObject, useEffect, useState } from 'react';
import * as lodash from 'lodash';
import { useFormik, FormikProps } from 'formik';
import * as yup from 'yup';
import { es } from 'yup-locales';
import { useSnackbar } from 'notistack';

import {
  Paper,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Button,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';

import webpayLogo from '../../assets/images/seller/payment/webpay.svg';
import kushkiLogo from '../../assets/images/seller/payment/kushki.svg';
import machLogo from '../../assets/images/seller/payment/mach.svg';
import fintocLogo from '../../assets/images/seller/payment/fintoc.svg';
import mercadoPago from '../../assets/images/seller/payment/mercado_pago.png';
import etPay from '../../assets/images/seller/payment/etpay.png';
import cardIcon from '../../assets/images/seller/card.svg';
import walletIcon from '../../assets/images/seller/wallet.svg';
import moneyBillIcon from '../../assets/images/seller/money_bill.svg';
import khipuLogo from '../../assets/images/seller/payment/khipu.svg';
import suitcaseIcon from '../../assets/images/seller/suitcase.svg';

import styles from './Payment.module.scss';
import formStyles from './components/Forms/Forms.module.scss';
import variables from '../../common/styles/variables.module.scss';
import { Payable, CardInfo } from '../../app/type';
import { compareDates } from '../../common/utils';
import KushkiCardForm from '../../common/components/KushkiCardForm';

yup.setLocale(es);

interface PaymentGatewaysProps {
  payable: Payable | undefined;
  cardRegistered: boolean;
  setCurrPaymentMethod: (arg: string) => void;
  currPaymentMethod: string;
  setCardRegistered: (arg: boolean) => void;
  selectPaymentMethod: (arg: string) => void;
  setCardInfo: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  kushkiTokenFail: boolean;
  mercadoPagoRef: MutableRefObject<null>;
  mpEmailRef: MutableRefObject<null>;
  mpIssuer: string;
  buyerInfo?: { email?: string; name?: string };
  formikBuyer?: FormikProps<{ [key: string]: any }>;
  mpLoaded: boolean;
  loadingPayment: boolean;
  setLoadingPayment: (arg: boolean) => void;
  overwriteStyles?: boolean;
  enrollPayment?: string;
}

const PaymentGateways = ({
  payable,
  cardRegistered,
  setCurrPaymentMethod,
  currPaymentMethod,
  setCardRegistered,
  selectPaymentMethod,
  setCardInfo,
  kushkiTokenFail,
  mercadoPagoRef,
  mpEmailRef,
  mpIssuer,
  buyerInfo,
  formikBuyer,
  mpLoaded,
  loadingPayment,
  setLoadingPayment,
  overwriteStyles,
  enrollPayment,
}: PaymentGatewaysProps): React.ReactElement => {
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const { enqueueSnackbar } = useSnackbar();
  let cardholderIcon = null;
  const [paymentMethodsToRender, setMethods] = useState<(React.ReactElement | false)[]>([]);
  const [isCharging, setIsCharging] = useState<boolean>(true);
  const [companyColor, setCompanyColor] = useState<string>('');

  const cardSchema = yup.object().shape({
    card_number: yup
      .string()
      .required('Número de tarjeta requerido')
      .max(19, 'Debe tener un largo máximo de 16 dígitos'),
    name: yup.string().required('Nombre del titular es requerido'),
    expiration_date: yup
      .string()
      .required('Fecha de vencimiento requerida')
      .min(5, 'Fecha de vencimiento inválida')
      .test('is-expiration-date-valid', 'Fecha de vencimiento inválida', (value) => {
        if (!value) {
          return false;
        }
        const currentYear = new Date().getFullYear() % 100;
        const currentMonth = new Date().getMonth() + 1;
        const [inputMonth, inputYear] = value.split('/').map((v) => parseInt(v, 10));
        return (
          inputYear >= currentYear &&
          inputMonth <= 12 &&
          (inputYear !== currentYear || (inputMonth >= currentMonth && inputMonth <= 12))
        );
      }),
    cvv: yup.string().required('CVV requerido').min(3, 'CVV inválido'),
  });

  const formikCard = useFormik<CardInfo>({
    initialValues: {
      card_number: '',
      name: '',
      expiration_date: '',
      cvv: undefined,
    },
    validationSchema: () => cardSchema,
    onSubmit: () => {
      setLoadingPayment(true);
      setCardInfo({
        ...formikCard.values,
        amount: payable?.company.zp_payment_commission
          ? Math.ceil(
              (payable?.render_amount || 1) * (1 + payable?.company.zp_payment_commission / 100)
            )
          : payable?.render_amount,
        payment_method: currPaymentMethod,
      });
    },
  });

  const handlePayment = () => {
    if (['kushki_single', 'kushki_recurrent'].includes(currPaymentMethod)) {
      formikBuyer?.isValid || payable?.buyer ? formikCard.submitForm() : formikBuyer?.submitForm();
    } else {
      selectPaymentMethod(currPaymentMethod);
    }
  };

  useEffect(() => {
    if (payable && payable.start_at) {
      const result = compareDates(payable.start_at);
      setIsCharging(result);
      if (!result) setCurrPaymentMethod('oneclick');
    } else if (enrollPayment && payable)
      setCurrPaymentMethod(payable?.default_payment_methods['card']);
  }, [payable]);

  useEffect(() => {
    if (kushkiTokenFail) {
      setLoadingPayment(false);
      enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
    }
  }, [kushkiTokenFail]);

  useEffect(() => {
    if (payable) {
      setCompanyColor(
        payable?.company.payment_design
          ? payable?.company.payment_design.background_color
          : '#4653E3'
      );
    }
  }, [payable]);

  switch (mpIssuer) {
    case 'visa':
      cardholderIcon = 'https://storage.googleapis.com/onlypays-public/assets/images/visa_icon.png';
      break;
    case 'master':
      cardholderIcon =
        'https://storage.googleapis.com/onlypays-public/assets/images/mastercard_icon.png';
      break;
    case 'amex':
      cardholderIcon = 'https://storage.googleapis.com/onlypays-public/assets/images/amex_icon.png';
      break;
    default:
      cardholderIcon = null;
  }

  useEffect(() => {
    if (
      payable?.enabled_payment_methods.every((element) =>
        ['oneclick', 'kushki_recurrent', 'recurrent_fintoc', 'recurrent_khipu'].includes(element)
      )
    ) {
      setCardRegistered(true);
    }
  }, [payable?.enabled_payment_methods]);

  useEffect(() => {
    setMethods(
      cardRegistered ||
        !isCharging ||
        lodash.isEqual(payable?.enabled_payment_methods, [
          'oneclick',
          'kushki_recurrent',
          'recurrent_fintoc',
          'recurrent_khipu',
        ]) ||
        enrollPayment
        ? [
            payable?.enabled_payment_methods.includes('oneclick') ? (
              <Paper
                key={'oneclick'}
                elevation={0}
                className={styles.paymentMethod}
                onClick={() => setCurrPaymentMethod('oneclick')}
              >
                <div>
                  <FormControlLabel
                    checked={currPaymentMethod === 'oneclick'}
                    value={'oneclick'}
                    control={
                      <Radio
                        style={
                          currPaymentMethod === 'oneclick'
                            ? {
                                color: companyColor,
                              }
                            : undefined
                        }
                      />
                    }
                    label={
                      <div>
                        <img src={cardIcon} className={styles.icon} />
                        <Typography>Tarjeta de Crédito o Débito (PAT)</Typography>
                      </div>
                    }
                  />
                </div>
                <img src={webpayLogo} />
              </Paper>
            ) : (
              false
            ),
            payable?.enabled_payment_methods.includes('kushki_recurrent') ? (
              <Fragment>
                <Paper
                  key={'kushki_recurrent'}
                  elevation={0}
                  className={styles.paymentMethod}
                  onClick={() => setCurrPaymentMethod('kushki_recurrent')}
                >
                  <div>
                    <FormControlLabel
                      checked={currPaymentMethod === 'kushki_recurrent'}
                      value={'kushki_recurrent'}
                      control={
                        <Radio
                          style={
                            currPaymentMethod === 'kushki_recurrent'
                              ? {
                                  color: companyColor,
                                }
                              : undefined
                          }
                        />
                      }
                      label={
                        <div>
                          <img src={cardIcon} className={styles.icon} />
                          <Typography>Tarjeta de Crédito o Débito (PAT)</Typography>
                        </div>
                      }
                    />
                  </div>
                  <img src={kushkiLogo} />
                </Paper>
                <div
                  style={
                    currPaymentMethod === 'kushki_recurrent' ? { display: '' } : { display: 'none' }
                  }
                >
                  <KushkiCardForm formik={formikCard} />
                </div>
              </Fragment>
            ) : (
              false
            ),
            payable?.enabled_payment_methods.includes('recurrent_fintoc') ? (
              <Paper
                key={'recurrent_fintoc'}
                elevation={0}
                className={styles.paymentMethod}
                onClick={() => setCurrPaymentMethod('recurrent_fintoc')}
              >
                <div>
                  <FormControlLabel
                    checked={currPaymentMethod === 'recurrent_fintoc'}
                    value={'recurrent_fintoc'}
                    control={
                      <Radio
                        style={
                          currPaymentMethod === 'recurrent_fintoc'
                            ? {
                                color: companyColor,
                              }
                            : undefined
                        }
                      />
                    }
                    label={
                      <div>
                        <img src={walletIcon} className={styles.icon} />
                        <Typography>Pago Automático a la Cuenta (PAC)</Typography>
                      </div>
                    }
                  />
                </div>
                <img src={fintocLogo} />
              </Paper>
            ) : (
              false
            ),
            payable?.enabled_payment_methods.includes('recurrent_khipu') ? (
              <Paper
                key={'recurrent_khipu'}
                elevation={0}
                className={styles.paymentMethod}
                onClick={() => setCurrPaymentMethod('recurrent_khipu')}
              >
                <div>
                  <FormControlLabel
                    checked={currPaymentMethod === 'recurrent_khipu'}
                    value={'recurrent_khipu'}
                    control={
                      <Radio
                        style={
                          currPaymentMethod === 'recurrent_khipu'
                            ? {
                                color: companyColor,
                              }
                            : undefined
                        }
                      />
                    }
                    label={
                      <div>
                        <img src={walletIcon} className={styles.icon} />
                        <Typography>Pago Automático a la Cuenta (PAC)</Typography>
                      </div>
                    }
                  />
                </div>
                <img src={khipuLogo} />
              </Paper>
            ) : (
              false
            ),
          ]
        : [
            payable?.enabled_payment_methods.includes('webpay_plus') ? (
              <Paper
                key={'webpay_plus'}
                elevation={0}
                className={styles.paymentMethod}
                onClick={() => setCurrPaymentMethod('webpay_plus')}
              >
                <div>
                  <FormControlLabel
                    checked={currPaymentMethod === 'webpay_plus'}
                    value={'webpay_plus'}
                    control={
                      <Radio
                        style={
                          currPaymentMethod === 'webpay_plus' ? { color: companyColor } : undefined
                        }
                      />
                    }
                    label={
                      <div>
                        <img src={cardIcon} className={styles.icon} />
                        <Typography>Tarjeta de Crédito o Débito</Typography>
                      </div>
                    }
                  />
                </div>
                <img src={webpayLogo} />
              </Paper>
            ) : (
              false
            ),
            payable?.enabled_payment_methods.includes('kushki_single') ? (
              <Fragment>
                <Paper
                  key={'kushki_single'}
                  elevation={0}
                  className={styles.paymentMethod}
                  onClick={() => setCurrPaymentMethod('kushki_single')}
                >
                  <div>
                    <FormControlLabel
                      checked={currPaymentMethod === 'kushki_single'}
                      value={'kushki_single'}
                      control={<Radio />}
                      label={
                        <div>
                          <img src={cardIcon} className={styles.icon} />
                          <Typography>Tarjeta de crédito o débito</Typography>
                        </div>
                      }
                    />
                  </div>
                  <img src={kushkiLogo} />
                </Paper>
                <div
                  style={
                    currPaymentMethod === 'kushki_single' ? { display: '' } : { display: 'none' }
                  }
                >
                  <KushkiCardForm formik={formikCard} />
                </div>
              </Fragment>
            ) : (
              false
            ),
            payable?.enabled_payment_methods.includes('mach') ? (
              <Paper
                key={'mach'}
                elevation={0}
                className={styles.paymentMethod}
                onClick={() => setCurrPaymentMethod('mach')}
              >
                <div>
                  <FormControlLabel
                    checked={currPaymentMethod === 'mach'}
                    value={'mach'}
                    control={
                      <Radio
                        style={currPaymentMethod === 'mach' ? { color: companyColor } : undefined}
                      />
                    }
                    label={
                      <div>
                        <img src={walletIcon} className={styles.icon} />
                        <Typography>Prepago</Typography>
                      </div>
                    }
                  />
                </div>
                <img src={machLogo} />
              </Paper>
            ) : (
              false
            ),
            payable?.enabled_payment_methods.includes('fintoc') ? (
              <Paper
                key={'fintoc'}
                elevation={0}
                className={styles.paymentMethod}
                onClick={() => setCurrPaymentMethod('fintoc')}
              >
                <div>
                  <FormControlLabel
                    checked={currPaymentMethod === 'fintoc'}
                    value={'fintoc'}
                    control={
                      <Radio
                        style={currPaymentMethod === 'fintoc' ? { color: companyColor } : undefined}
                      />
                    }
                    label={
                      <div>
                        <img src={moneyBillIcon} className={styles.icon} />
                        <Typography>Transferencia Bancaria</Typography>
                      </div>
                    }
                  />
                </div>
                <img src={fintocLogo} />
              </Paper>
            ) : (
              false
            ),
            payable?.enabled_payment_methods.includes('mercado_pago_single') && mpLoaded ? (
              <Paper
                key={'mercado_pago'}
                elevation={0}
                className={styles.paymentMethod}
                onClick={() => setCurrPaymentMethod('mercado_pago')}
              >
                <div>
                  <FormControlLabel
                    checked={currPaymentMethod === 'mercado_pago'}
                    value={'mercado_pago'}
                    control={
                      <Radio
                        style={
                          currPaymentMethod === 'mercado_pago' ? { color: companyColor } : undefined
                        }
                      />
                    }
                    label={
                      <div>
                        <img src={cardIcon} className={styles.icon} />
                        <Typography>Tarjeta de Crédito o Débito</Typography>
                      </div>
                    }
                  />
                </div>
                <img src={mercadoPago} />
              </Paper>
            ) : (
              false
            ),
            payable?.enabled_payment_methods.includes('et_pay_business') ? (
              <Paper
                key={'et_pay_business'}
                elevation={0}
                className={styles.paymentMethod}
                onClick={() => setCurrPaymentMethod('et_pay_business')}
              >
                <div>
                  <FormControlLabel
                    checked={currPaymentMethod === 'et_pay_business'}
                    value={'et_pay_business'}
                    control={
                      <Radio
                        style={
                          currPaymentMethod === 'et_pay_business'
                            ? { color: companyColor }
                            : undefined
                        }
                      />
                    }
                    label={
                      <div>
                        <img src={suitcaseIcon} className={styles.icon} />
                        <Typography>Transferencia automatizada para empresas</Typography>
                      </div>
                    }
                  />
                </div>
                <img src={etPay} />
              </Paper>
            ) : (
              false
            ),
          ]
    );
  }, [
    formikCard.values,
    formikCard.errors,
    mpLoaded,
    payable?.enabled_payment_methods,
    cardRegistered,
    currPaymentMethod,
    companyColor,
  ]);

  useEffect(() => {
    lodash.isEqual(payable?.enabled_payment_methods, ['oneclick']) &&
      setCurrPaymentMethod('oneclick');
  }, [payable?.enabled_payment_methods]);

  const mercadoPagoRender = (
    <div
      style={currPaymentMethod === 'mercado_pago' ? { display: '' } : { display: 'none' }}
      className={styles.formCheckoutContainer}
    >
      <form id="formCheckout" className={styles.formCheckout}>
        <div className={styles.formCardNumberContainer}>
          {cardholderIcon && <img src={cardholderIcon} className={styles.cardHolderProvider} />}
          <div id="formCheckoutCardNumber" className={styles.formCardNumber}></div>
        </div>
        <div className={styles.formDateAndCode}>
          <div id="formCheckoutExpirationDate" className={styles.formBaseContainer}></div>
          <div id="formCheckoutSecurityCode" className={styles.formBaseContainer}></div>
        </div>
        <input type="text" id="formCheckoutCardholderName" className={styles.formBaseContainer} />
        <select id="formCheckoutIssuer" className={styles.hidden}></select>
        <select id="formCheckoutInstallments" className={styles.hidden}></select>
        <div className={styles.formCardNumberContainer}>
          <select
            id="formCheckoutIdentificationType"
            className={styles.mpIdentificationNumber}
          ></select>
          <input
            type="text"
            id="formCheckoutIdentificationNumber"
            className={styles.mpIdentificationNumberField}
          />
        </div>
        <input
          type="email"
          id="formCheckoutCardholderEmail"
          ref={mpEmailRef}
          className={styles.hidden}
        />

        <button
          type="submit"
          id="formCheckoutSubmit"
          className={styles.hidden}
          ref={mercadoPagoRef}
        >
          Pagar
        </button>
      </form>
    </div>
  );

  return (
    <Fragment>
      <div
        className={`${overwriteStyles && 'containerPaymentGateways'} ${
          styles.containerPaymentGateways
        }`}
      >
        {payable?.company.block === 'payments' ? (
          <div className={styles.warningContainer}>
            <Typography variant="h5">Empresa no habilitada para recibir pagos</Typography>
            <Typography align="center" variant="h6">
              Cualquier duda contactános con{' '}
              <a href={`mailto:${payable?.contact_email}`} style={{ color: '#1f1f1f' }}>
                {payable?.contact_email}
              </a>{' '}
              {payable?.contact_phone && ` o al ${payable?.contact_phone}`}
            </Typography>
          </div>
        ) : (
          <Fragment>
            <div className={formStyles.titleForm}>
              <Typography
                className={formStyles.title}
                id="clientForm"
                style={{ color: companyColor }}
              >
                Selecciona el medio de pago
              </Typography>
              <Typography className={formStyles.stepDescription}>
                Elige el medio de pago y sigue los pasos
              </Typography>
            </div>
            <div className={`${overwriteStyles && 'paymentMethods'} ${styles.paymentMethods}`}>
              <FormControl variant="standard" className={styles.formRadio}>
                <RadioGroup
                  name="Card Registered"
                  value={cardRegistered}
                  onChange={(event: any) => setCurrPaymentMethod(event.target.value)}
                >
                  {paymentMethodsToRender}
                </RadioGroup>
              </FormControl>
              {cardRegistered && (
                <div className={styles.automaticPaymentMessage}>
                  <Typography fontSize={isMobile ? 12 : 14} fontStyle="italic">
                    *Se harán cobros automáticos al medio de pago seleccionado según el servicio
                    suscrito.
                  </Typography>
                  {payable?.payment_link_configuration?.transbank_charge_message &&
                    currPaymentMethod === 'oneclick' && (
                      <Typography mt={2} fontSize={isMobile ? 12 : 14} fontStyle="italic">
                        **Se cobrarán $50 con devolución para validar la tarjeta. Luego se cobrará
                        el monto del servicio contratado.
                      </Typography>
                    )}
                </div>
              )}
              {mercadoPagoRender}
              {payable?.save_card_enabled &&
                payable?.default_payment_methods['single'] &&
                isCharging &&
                !enrollPayment && (
                  <Fragment>
                    <FormControl className={styles.subscribeCardForm}>
                      <FormLabel>
                        ¿Quieres inscribir un medio de pago para realizar pagos automáticos?
                      </FormLabel>
                      <RadioGroup
                        row
                        name="Card Registered"
                        value={cardRegistered}
                        onChange={() => {
                          setCardRegistered(!cardRegistered);
                          if (cardRegistered) {
                            setCurrPaymentMethod(payable.default_payment_methods['single']);
                          } else {
                            setCurrPaymentMethod(payable.default_payment_methods['card']);
                          }
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={
                            <Radio style={cardRegistered ? { color: companyColor } : undefined} />
                          }
                          label="Si"
                        />
                        <FormControlLabel
                          value={false}
                          control={
                            <Radio style={!cardRegistered ? { color: companyColor } : undefined} />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Fragment>
                )}
            </div>
            {isMobile ? (
              <div className={styles.paymentButtonContainer}>
                <Button
                  variant="contained"
                  onClick={() => {
                    handlePayment();
                  }}
                  style={{
                    backgroundColor: loadingPayment ? undefined : companyColor,
                  }}
                  className={styles.payButton}
                  disabled={loadingPayment}
                >
                  {loadingPayment ? (
                    <CircularProgress size={20} />
                  ) : buyerInfo || !isCharging ? (
                    'Suscribirme'
                  ) : (
                    'Finalizar pago'
                  )}
                </Button>
              </div>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  selectPaymentMethod(currPaymentMethod);
                }}
                style={{
                  backgroundColor: loadingPayment ? undefined : companyColor,
                }}
                className={styles.payButton}
                disabled={loadingPayment}
              >
                {loadingPayment ? (
                  <CircularProgress size={20} />
                ) : buyerInfo || !isCharging ? (
                  'Suscribirme'
                ) : enrollPayment ? (
                  'Inscribir'
                ) : (
                  'Finalizar pago'
                )}
              </Button>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default PaymentGateways;
