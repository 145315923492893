import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FormikProps, useFormik, getIn } from 'formik';
import { useDispatch, useSelector } from '../../../app/hooks';
import { SellerState, setPaymentPlan } from '../sellerSlice';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import lodash from 'lodash';

import {
  PaymentPlan,
  BankInformation,
  Product,
  Buyer,
  Company,
  ApiList,
  Statement,
  ApiObject,
  BillingInformation,
  InvoiceConfiguration,
  InvoiceInformation,
} from '../../../app/type';
import { sellerApi } from '../../../common/api';

import PopUp from '../../../common/components/PopUp';
import TableList from '../../../common/components/TableList';
import InvoicingSetupForm from '../../../common/components/InvoicingSetupForm';

import styles from './Index.module.scss';
import cStyles from '../../../common/styles/common.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import {
  formatNumber,
  plainNumberFormatter,
  formatDecimalNumber,
  decimalNumberFormatter,
  RUTFormater,
  validateRut,
  formatRUT,
} from '../../../common/utils';
import { CURRENCIES } from '../../../common/constants/currencies';
import { COMMUNES, COMMUNES_BY_REGION } from '../../../common/constants/communes';
import { ACCOUNT_TYPES, BANKS } from '../../../common/constants/banks';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import ButtonGroup from '@mui/material/ButtonGroup';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slider from '@mui/material/Slider';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Search as SearchIcon } from 'react-feather';

import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import { generateImage } from '../../../common/utils/cropImage';
import { Container, Switch } from '@mui/material';
import { FRONT_URL } from '../../../common/api/client';
import { InfoButton } from '../../../common/components/InfoButton';
import { REGIONS } from '../../../common/constants/regions';

let queryTimeout: ReturnType<typeof setTimeout>;

interface PaymentPlanFormProps {
  initialData?: PaymentPlan;
  onSuccess: (plan: ApiObject<PaymentPlan>) => void;
}

interface PaymentPlanInfo
  extends Partial<
    Omit<PaymentPlan, 'product' | 'buyer' | 'bank_information' | 'billing_information'>
  > {
  extra_fields?: { [key: string]: string };
  product: Partial<Product>;
  buyer: Partial<Buyer>;
  bank_information?: Partial<BankInformation>;
  billing_information?: Partial<BillingInformation>;
  invoice_configuration?: InvoiceConfiguration;
  invoice_information?: InvoiceInformation;
}

const PaymentPlanSchema = yup.object().shape({
  product: yup.object().shape({
    name: yup.string().required('Nombre del producto es requerido'),
  }),
  buyer: yup.object().shape({
    email: yup.string().email('Correo del cliente es requerido').required(),
    name: yup.string().required('Nombre del cliente es requerido'),
  }),
});

const InvoiceConfigSchema = yup.object().shape({
  document: yup.string().required().label('Tipo de documento'),
  vat_exempt: yup.boolean().required().label('IVA'),
  emit_at: yup.string().required().label('Emisión documento'),
});

const PaymentPlanForm = (props: PaymentPlanFormProps): React.ReactElement => {
  const { company, paymentPlan } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAttempt, setIsAttempt] = useState<boolean>(true);
  const [warningPopUpOpen, setWarningPopUpOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [advancedSettings, setAdvancedSettings] = useState<boolean>(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState<boolean>(false);
  const [invoiceConfiguration, setInvoiceConfiguration] = useState<InvoiceConfiguration>();
  const [invoiceDocument, setInvoiceDocument] = useState<string>();

  const initialValues =
    props.initialData ||
    ({
      company_id: company?.id,
      extra_fields: {},
      emit_document:
        // @ts-expect-error: ts(2339)
        props.initialData?.emit_document === false
          ? false
          : company?.invoicing_provider
          ? true
          : null,
      product: {
        company_id: company?.id || '',
        name: '',
        description: '',
        recurrence: 'month',
        value: 0,
        periods: undefined,
        dop: 1,
        is_recurrent: true,
        has_periods: false,
        has_dop: false,
        has_et: false,
        extra_fields: {},
        currency: 'CLP',
        has_stock: false,
        has_limit_date: false,
        has_apportion: false,
      },
      buyer: {
        company_id: company?.id || '',
        name: '',
        email: '',
      },
      bank_information: {
        company_id: company?.id || '',
        id: company?.default_bank_information?.id || '',
        name: company?.default_bank_information?.name || '',
        tax_id: company?.default_bank_information?.tax_id || '',
        bank: company?.default_bank_information?.bank || '',
        account_type: company?.default_bank_information?.account_type || '',
        account_number: company?.default_bank_information?.account_number || '',
        email: company?.default_bank_information?.email || '',
      },
      billing_information: {
        company_id: company?.id || '',
        id: company?.default_billing_information?.id || '',
        business_name: company?.default_billing_information?.business_name || '',
        tax_id: company?.default_billing_information?.tax_id || '',
        address: company?.default_billing_information?.address || '',
        commune: company?.default_billing_information?.commune || '',
        activity: company?.default_billing_information?.activity || '',
        email: company?.default_billing_information?.email || '',
        default: true,
      },
      statements: [],
    } as PaymentPlanInfo);

  const invoiceConfigurationFormik = useFormik<InvoiceConfiguration>({
    initialValues: {
      id: props.initialData?.invoice_configuration?.document || '',
      document: props.initialData?.invoice_configuration?.document || '',
      vat_exempt: props.initialData?.invoice_configuration?.vat_exempt || false,
      emit_at: props.initialData?.invoice_configuration?.emit_at || '',
      emit_credit_note: props.initialData?.invoice_configuration?.emit_credit_note || false,
      ask_rut: props.initialData?.invoice_configuration?.ask_rut,
    },
    validationSchema: InvoiceConfigSchema,
    onSubmit: () => {
      setInvoiceModalOpen(false);
      setInvoiceConfiguration(invoiceConfigurationFormik.values);
      setInvoiceDocument(
        invoiceConfigurationFormik.values.document === 'invoice' ? 'invoice' : 'receipt'
      );
    },
    enableReinitialize: true,
  });

  const invoiceInformationSchema = useMemo(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const conditionalRequired = (fieldName: string) =>
      invoiceDocument === 'invoice'
        ? yup.string().required().label(fieldName)
        : yup.string().label(fieldName);
    const rutConditionalRequired = (fieldName: string) =>
      invoiceDocument === 'invoice' ||
      (invoiceDocument === 'receipt' && invoiceConfiguration?.ask_rut)
        ? yup.string().required().label(fieldName)
        : yup.string().label(fieldName);

    return yup.object().shape({
      region: conditionalRequired('Región'),
      commune: conditionalRequired('Comuna'),
      address: conditionalRequired('Dirección'),
      rut: rutConditionalRequired('Rut').test(
        'is-valid-rut',
        'Rut ingresado inválido',
        (value) => !value || validateRut(value)
      ),
      business_name: conditionalRequired('Razón social'),
      activity: conditionalRequired('Giro'),
      email: yup
        .string()
        .email()
        .matches(emailRegex, 'Email inválido, no puede contener caracteres especiales')
        .label('Email de contacto')
        .test('gmail-domain', 'Dominio del correo inválido, debe terminar en .com', (value) => {
          if (!value) return true;
          const domain = value.split('@')[1];
          if (domain && (domain.startsWith('gmail') || domain.startsWith('hotmail'))) {
            return domain === 'gmail.com' || domain === 'hotmail.com';
          }
          return true;
        }),
    });
  }, [invoiceDocument]);

  const addInvoiceInformation = (invoiceInfo: InvoiceInformation) => {
    formik.setFieldValue('invoice_information', invoiceInfo);
    submitFormik({ ...formik.values, invoice_information: invoiceInfo }, {});
  };

  const invoiceInformationFormik = useFormik<InvoiceInformation>({
    initialValues: {
      id: '',
      region: '',
      commune: '',
      address: '',
      rut: '',
      business_name: '',
      activity: '',
      email: '',
    },
    validationSchema: () => invoiceInformationSchema,
    onSubmit: addInvoiceInformation,
    enableReinitialize: true,
  });

  const submitFormik = (paymentPlanInfo: PaymentPlanInfo, { setErrors }: any) => {
    const sendRequest = () => {
      const request = props.initialData?.id
        ? sellerApi.paymentPlans.update
        : sellerApi.paymentPlans.create;
      const body =
        formik.values.emit_document &&
        [true, false].includes(invoiceConfigurationFormik.values.vat_exempt) &&
        invoiceConfigurationFormik.values.emit_at
          ? { ...paymentPlanInfo, invoice_configuration: invoiceConfigurationFormik.values }
          : paymentPlanInfo;
      request(body)
        .then(props.onSuccess)
        .catch((err: any): void => {
          if (err?.response?.status === 400) {
            setErrors(err.response.data?.meta?.errors);
          } else {
            enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
          }
        })
        .finally(() => setLoading(false));
    };
    setLoading(true);
    if (isAttempt && !props.initialData?.id) {
      sellerApi.paymentPlans
        .createAttempt({
          companyId: paymentPlanInfo.company_id,
          email: paymentPlanInfo.buyer.email,
          name: paymentPlanInfo.product.name,
        })
        .then(sendRequest)
        .catch(() => {
          setIsAttempt(false);
          setWarningPopUpOpen(true);
        })
        .finally(() => setLoading(false));
    } else {
      sendRequest();
    }
  };

  const formik = useFormik<PaymentPlanInfo>({
    initialValues,
    validationSchema: PaymentPlanSchema,
    onSubmit: !paymentPlan && invoiceDocument ? invoiceInformationFormik.submitForm : submitFormik,
  });

  const cancelCreation = () => {
    setIsAttempt(true);
    setWarningPopUpOpen(false);
  };

  useEffect(() => {
    if (!paymentPlan && company?.id)
      sellerApi.companies.invoiceConfiguration(company?.id).then((data) => {
        setInvoiceConfiguration(data.data);
      });
  }, [company?.id]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <ProductForm
          formik={formik}
          company={company}
          invoiceConfigurationFormik={invoiceConfigurationFormik}
          paymentPlan={paymentPlan}
          invoiceModalState={{
            open: invoiceModalOpen,
            setOpen: setInvoiceModalOpen,
          }}
          loading={loading}
          setLoading={setLoading}
        />
        <BuyerForm
          formik={formik}
          company={company}
          initialData={props.initialData}
          invoiceInformationFormik={invoiceInformationFormik}
          invoiceConfigurationState={{
            state: invoiceConfiguration,
            setState: setInvoiceConfiguration,
          }}
          invoiceDocumentState={{ state: invoiceDocument || '', setState: setInvoiceDocument }}
          paymentPlan={paymentPlan}
        />
        <StatementsForm
          formik={formik}
          company={company}
          loading={loading}
          initialData={props.initialData}
        />
        {/* <Paper className={styles.mainPaper}>
          <div className={styles.mainEditContainer}>
            <div className={styles.advancedSettings}>
              <Typography variant="subtitle1" color={variables.primaryDarkerColor}>
                Configuración avanzada
              </Typography>
              <Switch
                checked={advancedSettings}
                onChange={() => setAdvancedSettings(!advancedSettings)}
              />
            </div>
          </div>
        </Paper> */}
        {advancedSettings && (
          <BankInformationForm formik={formik} company={company} initialData={props.initialData} />
        )}
        {advancedSettings && (
          <BillingInformationForm
            formik={formik}
            company={company}
            initialData={props.initialData}
          />
        )}
        <Button
          disableElevation
          size="large"
          variant="contained"
          color="primary"
          type="submit"
          className="loader"
          disabled={loading}
        >
          Guardar
        </Button>
      </form>
      <PopUp
        state={{ open: warningPopUpOpen, setOpen: setWarningPopUpOpen }}
        content={
          <Typography>
            Ya existe otro plan de pago con este nombre y este cliente. ¿Estás seguro que quieres
            continuar?
          </Typography>
        }
        extraActions={[
          <Button key={1} variant="outlined" onClick={cancelCreation}>
            Cancelar
          </Button>,
          <Button key={2} variant="contained" type="submit" onClick={formik.submitForm}>
            Crear plan de pago
          </Button>,
        ]}
      />
    </>
  );
};

interface FormComponentProps {
  formik: FormikProps<PaymentPlanInfo>;
  company?: Company;
  loading?: boolean;
  initialData?: PaymentPlan;
}

interface ProductFormComponentProps {
  formik: FormikProps<PaymentPlanInfo>;
  company?: Company;
  loading: boolean;
  setLoading: (s: boolean) => void;
  initialData?: PaymentPlan;
  invoiceConfigurationFormik: FormikProps<InvoiceConfiguration>;
  paymentPlan?: PaymentPlan;
  invoiceModalState: {
    open: boolean;
    setOpen: (s: boolean) => void;
  };
}

interface BuyerFormComponentProps {
  formik: FormikProps<PaymentPlanInfo>;
  company?: Company;
  loading?: boolean;
  initialData?: PaymentPlan;
  invoiceInformationFormik: FormikProps<InvoiceInformation>;
  invoiceConfigurationState: {
    state: InvoiceConfiguration | undefined;
    setState: (s: InvoiceConfiguration) => void;
  };
  invoiceDocumentState: {
    state: string;
    setState: (s: string) => void;
  };
  paymentPlan: PaymentPlan | undefined;
}

const ProductForm = ({
  formik,
  loading,
  setLoading,
  company,
  paymentPlan,
  invoiceConfigurationFormik,
  invoiceModalState,
}: ProductFormComponentProps): React.ReactElement => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [cleanConfirmation, setCleanConfirmation] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState<string>('');
  const [croppedArea, setCroppedArea] = React.useState<Area>();
  const [urlImage, setUrlImage] = useState<string>(formik.values.product.image || '');
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const dispatch = useDispatch();

  const addExtraField = () => {
    if (formik.values.product.extra_fields) {
      let len = Object.keys(formik.values.product.extra_fields).length;
      if (len == 0) {
        formik.setFieldValue('product.extra_fields', { '1-': '' });
      } else {
        while (Object.keys(formik.values.product.extra_fields).includes(`${len}-`)) {
          len += 1;
        }
        formik.setFieldValue('product.extra_fields', {
          ...formik.values.product.extra_fields,
          [`${len}-`]: '',
        });
      }
    }
  };

  const cleanExtraFields = () => {
    formik.setFieldValue('product.extra_fields', {});
    formik.setFieldValue('extra_fields', {});
    setCleanConfirmation(false);
  };

  const onUploadImage = (event: any) => {
    setDialogOpen(!dialogOpen);
    setImage(URL.createObjectURL(event.target.files[0]));
  };

  const onCropComplete = useCallback(async (_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const acceptImage = async () => {
    if (croppedArea) {
      const canvas = await generateImage(image, croppedArea);
      canvas
        ? canvas.toBlob(
            (blob: Blob | null) => {
              if (blob) {
                const newImg = document.createElement('img'),
                  url = URL.createObjectURL(blob);

                newImg.onload = () => {
                  URL.revokeObjectURL(url);
                };
                setUrlImage(URL.createObjectURL(blob));
                newImg.src = url;
                formik.setFieldValue('product.image_file', blob);
                enqueueSnackbar('Imagen procesada con éxito', { variant: 'success' });
              }
            },
            'image/png',
            0.66
          )
        : null;
      setDialogOpen(!dialogOpen);
    }
  };

  const deleteSubscriptionInvoiceConfig = () => {
    setLoading(true);
    sellerApi.paymentPlans
      .deleteInvoiceConfiguration(
        paymentPlan?.id || '',
        paymentPlan?.invoice_configuration?.id || ''
      )
      .then((data) => {
        dispatch(setPaymentPlan(data.data));
        invoiceModalState?.setOpen(false);
        enqueueSnackbar('Información actualizada exitosamente', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
      })
      .finally(() => setLoading(false));
  };

  const openInvoiceConfig = () => {
    window.open(`${FRONT_URL}/seller/company/invoicing_setup`);
  };

  return (
    <Paper className={styles.mainPaper}>
      <div className={styles.mainEditContainer}>
        <div className={styles.title}>
          <Typography variant="h6">Datos del plan de pago</Typography>
        </div>
        <div className={styles.paymentPlanEditContainer}>
          <div className={styles.image}>
            <Container
              id="imageContainer"
              className={styles.containerProductImage}
              sx={{ backgroundImage: `url(${urlImage})` }}
            >
              <Button className={styles.buttonProductImage} component="label">
                <CameraAltOutlinedIcon sx={{ color: '#FFFFFF' }} />
                <input type="file" accept="image/*" hidden id="image" onChange={onUploadImage} />
              </Button>
            </Container>
          </div>
          <div className={styles.fields}>
            <div className={styles.field}>
              <Typography variant="subtitle1">Nombre*</Typography>
              <TextField
                fullWidth
                required
                id="product.name"
                type="text"
                autoComplete="product.name"
                variant="outlined"
                value={formik.values.product.name}
                onChange={formik.handleChange}
                error={formik.touched.product?.name && Boolean(formik.errors.product?.name)}
                helperText={formik.touched.product?.name && formik.errors.product?.name}
              />
            </div>
            <div className={styles.field}>
              <Typography variant="subtitle1">Descripción</Typography>
              <TextField
                fullWidth
                id="product.description"
                type="text"
                autoComplete="product.description"
                variant="outlined"
                value={formik.values.product.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.product?.description && Boolean(formik.errors.product?.description)
                }
                helperText={
                  formik.touched.product?.description && formik.errors.product?.description
                }
              />
            </div>
          </div>
        </div>
        {company?.invoicing_provider && (
          <div className={styles.fieldContainer}>
            <Grid
              item
              container
              xs={isMobile ? 12 : 4}
              md={6}
              alignItems="center"
              sx={{ flexWrap: 'nowrap' }}
            >
              <Typography variant="subtitle1">Emitir boleta/factura</Typography>
              <InfoButton text="emitDocument" context="paymentPlan" />
            </Grid>
            <div>
              <TextField
                fullWidth
                select
                variant="outlined"
                defaultValue={formik.values.emit_document ? 'true' : 'false'}
                onChange={(event) =>
                  formik.setFieldValue(
                    'emit_document',
                    event.target.value === 'true' ? true : false
                  )
                }
                className={styles.option}
              >
                <MenuItem value={'true'}>Sí</MenuItem>
                <MenuItem value={'false'}>No</MenuItem>
              </TextField>
              {formik.values.emit_document && (
                <Fragment>
                  <Typography mt={0.5} fontSize={14}>
                    *Se pedirán automáticamente los datos necesarios para emitir el documento cuando
                    el cliente se inscriba al servicio.
                  </Typography>
                  <div className={styles.shippingExplain}>
                    {paymentPlan?.invoice_configuration ? (
                      <Typography>
                        <a
                          className={styles.addExtraFieldButton}
                          onClick={() => invoiceModalState?.setOpen(true)}
                        >
                          Editar configuración de emisión de boleta/factura
                        </a>
                      </Typography>
                    ) : (
                      <Typography>
                        Se ocupará por defecto la{' '}
                        <a className={styles.addExtraFieldButton} onClick={openInvoiceConfig}>
                          configuración empresa
                        </a>
                        . O puedes definir otra configuración para este plan de pago{' '}
                        <a
                          className={styles.addExtraFieldButton}
                          onClick={() => invoiceModalState?.setOpen(true)}
                        >
                          aquí.
                        </a>
                      </Typography>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        )}
        <div className={styles.productExtraFields}>
          <div className={styles.productOptions}>
            <ButtonGroup variant="outlined">
              {Object.keys(formik.values.product.extra_fields || {}).length !== 0 && (
                <Button onClick={() => setCleanConfirmation(true)}>Limpiar campos extra</Button>
              )}
              <Button onClick={() => setDrawerOpen(true)}>Agregar campos</Button>
            </ButtonGroup>
          </div>
          <div className={styles.content}>
            <div className={styles.clientForm}>
              {Object.keys(formik.values.product.extra_fields || {}).reduce(
                (prev: React.ReactElement[], curr: string, index): React.ReactElement[] => {
                  if (index % 2 === 0) {
                    const nextKey = Object.keys(formik.values.product.extra_fields || {})[
                      index + 1
                    ];
                    prev.push(
                      <>
                        <div className={styles.clientFormRow}>
                          <div className={styles.clientFormCell}>
                            <Typography variant="subtitle1">
                              {formik.values.product.extra_fields?.[curr]}
                            </Typography>
                            <TextField
                              fullWidth
                              variant="outlined"
                              value={formik.values.extra_fields?.[curr]}
                              onChange={(event: any) =>
                                formik.setFieldValue('extra_fields', {
                                  ...formik.values.extra_fields,
                                  [curr]: event.target.value.toString(),
                                })
                              }
                            />
                          </div>
                          {nextKey && (
                            <div className={styles.clientFormCell}>
                              <Typography variant="subtitle1">
                                {formik.values.product.extra_fields?.[nextKey]}
                              </Typography>
                              <TextField
                                fullWidth
                                variant="outlined"
                                value={formik.values.extra_fields?.[nextKey]}
                                onChange={(event: any) =>
                                  formik.setFieldValue('extra_fields', {
                                    ...formik.values.extra_fields,
                                    [nextKey]: event.target.value.toString(),
                                  })
                                }
                              />
                            </div>
                          )}
                        </div>
                      </>
                    );
                  }
                  return prev;
                },
                []
              )}
            </div>
          </div>
        </div>
      </div>
      <SwipeableDrawer
        anchor={'right'}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
      >
        <div className={styles.drawer}>
          <div className={styles.header}>
            <Typography>Agregar campos extra</Typography>
            <FontAwesomeIcon
              icon={faXmark}
              onClick={() => setDrawerOpen(false)}
              className={styles.icon}
            />
          </div>
          {Object.keys(formik.values.product.extra_fields || {}).map((id, index) => {
            return (
              <div key={index} className={styles.drawerRow}>
                <div className={styles.drawerItem}>
                  <Typography variant="subtitle1">Nombre campo adicional</Typography>
                  <TextField
                    fullWidth
                    value={formik.values.product.extra_fields?.[id]}
                    onChange={(event: any) =>
                      formik.setFieldValue('product.extra_fields', {
                        ...formik.values.product.extra_fields,
                        [id]: event.target.value.toString(),
                      })
                    }
                  />
                </div>
                <div className={styles.drawerItem}>
                  <Typography variant="subtitle1">Rellenar campo adicional</Typography>
                  <TextField
                    fullWidth
                    value={formik.values.extra_fields?.[id]}
                    onChange={(event: any) =>
                      formik.setFieldValue('extra_fields', {
                        ...formik.values.extra_fields,
                        [id]: event.target.value.toString(),
                      })
                    }
                    placeholder={formik.values.product.extra_fields?.[id]}
                  />
                </div>
                <div className={styles.deleteRow}>
                  {isMobile ? (
                    <Button
                      onClick={() => {
                        const newExtraFields = lodash.omit(formik.values.extra_fields, [id]);
                        formik.setFieldValue('extra_fields', newExtraFields);
                        const newProductExtraFields = lodash.omit(
                          formik.values.product.extra_fields,
                          [id]
                        );
                        formik.setFieldValue('product.extra_fields', newProductExtraFields);
                      }}
                      variant="outlined"
                    >
                      Eliminar campo extra
                    </Button>
                  ) : (
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      onClick={() => {
                        const newExtraFields = lodash.omit(formik.values.extra_fields, [id]);
                        formik.setFieldValue('extra_fields', newExtraFields);
                        const newProductExtraFields = lodash.omit(
                          formik.values.product.extra_fields,
                          [id]
                        );
                        formik.setFieldValue('product.extra_fields', newProductExtraFields);
                      }}
                    />
                  )}
                </div>
                {isMobile && <Divider />}
              </div>
            );
          })}
          <ButtonGroup className={styles.actions}>
            <Button onClick={addExtraField}>Agregar campo</Button>
            <Button onClick={() => setDrawerOpen(false)}>Guardar</Button>
          </ButtonGroup>
        </div>
      </SwipeableDrawer>
      <PopUp
        state={{ open: cleanConfirmation, setOpen: setCleanConfirmation }}
        content={
          <div>
            <Typography>
              Se eliminarán todos los campos extras, ¿estás seguro que quieres continuar?
            </Typography>
          </div>
        }
        extraActions={[
          <Button key={1} onClick={cleanExtraFields} variant="contained">
            Sí
          </Button>,
          <Button key={2} onClick={() => setCleanConfirmation(false)} variant="outlined">
            No
          </Button>,
        ]}
      />
      <Dialog open={dialogOpen} keepMounted onClose={() => setDialogOpen(!dialogOpen)}>
        <Grid container>
          <Grid item xs={12}>
            <DialogTitle>Ajusta la imagen por favor</DialogTitle>
          </Grid>
          <Grid item xs={12}>
            <DialogContent>
              <div style={{ position: 'relative', width: '100%', height: 250 }}>
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  objectFit="contain"
                />
              </div>
              <div>
                <Slider
                  value={zoom}
                  min={1}
                  max={2}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(Number(zoom))}
                  classes={{ root: 'slider' }}
                />
              </div>
            </DialogContent>
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <Button onClick={() => setDialogOpen(!dialogOpen)}>Cancelar</Button>
              <Button onClick={acceptImage}>Aceptar</Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
      <PopUp
        state={{ open: invoiceModalState.open, setOpen: invoiceModalState.setOpen }}
        content={
          <div className={styles.invoiceConfigContent}>
            <Typography variant="h6">
              Configura las sigiuientes opciones para este plan de pago
            </Typography>
            <InvoicingSetupForm formik={invoiceConfigurationFormik} />
            {paymentPlan?.invoice_configuration && (
              <Typography mt={0} mb={2}>
                <a className={styles.addExtraFieldButton} onClick={deleteSubscriptionInvoiceConfig}>
                  Borrar configuración
                </a>
                . (Se utilizará por defecto la{' '}
                <a className={styles.addExtraFieldButton} onClick={openInvoiceConfig}>
                  configuración empresa
                </a>
                )
              </Typography>
            )}
            <div className={styles.submitForm}>
              <Button
                disableElevation
                size="large"
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => invoiceConfigurationFormik.handleSubmit()}
              >
                {loading ? <CircularProgress size={20} /> : 'Continuar'}
              </Button>
            </div>
          </div>
        }
      />
    </Paper>
  );
};

const BuyerForm = ({
  formik,
  company,
  initialData,
  invoiceInformationFormik,
  invoiceConfigurationState,
  invoiceDocumentState,
  paymentPlan,
}: BuyerFormComponentProps): React.ReactElement => {
  const [buyerSelected, setBuyerSelected] = useState<Buyer | undefined>(initialData?.buyer);
  const [query, setQuery] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [buyers, setBuyers] = useState<Buyer[]>([]);
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (queryTimeout) clearTimeout(queryTimeout);

    setLoading(true);
    queryTimeout = setTimeout(() => {
      setLoading(false);
      setQuery(event.target.value);
    }, 1000);
  };

  const startAd = (): React.ReactElement => {
    return (
      <InputAdornment position="start">
        {loading ? <CircularProgress size={18} /> : <SearchIcon />}
      </InputAdornment>
    );
  };

  const loadBuyers = () => {
    if (company?.id) {
      setLoading(true);
      sellerApi.buyers
        .list(company.id, query)
        .then((data: ApiList<Buyer>) => {
          setBuyers([...data.data]);
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    loadBuyers();
  }, [company?.id, query]);

  const selectBuyer = (res: Buyer) => {
    const { name, email } = res;
    formik.setFieldValue('buyer', { ...formik.values.buyer, name: name, email: email } as Buyer);
    setBuyerSelected({ ...buyerSelected, name: name, email: email } as Buyer);
  };

  const resetBuyer = () => {
    if (buyerSelected) {
      setBuyerSelected(undefined);
      formik.setFieldValue('buyer', { company_id: company?.id || '', name: '', email: '' });
    } else {
      setBuyerSelected({ company_id: company?.id || '' } as Buyer);
    }
  };

  return (
    <>
      <Paper className={styles.mainPaper}>
        <div className={styles.mainEditContainer}>
          <div className={styles.title}>
            <Typography variant="h6">Datos del cliente</Typography>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.options}>
            {initialData ? null : (
              <Button onClick={resetBuyer} variant="outlined">
                {buyerSelected ? 'Buscar cliente' : 'Crear cliente nuevo'}
              </Button>
            )}
          </div>
          <div>
            {buyerSelected ? null : (
              <div>
                <div className={cStyles.modal} style={{ marginTop: '0px', padding: '0px' }}>
                  <div style={{ textAlign: 'center', margin: 'auto' }}>
                    <TextField
                      hiddenLabel
                      className={styles.queryBox}
                      id="query"
                      type="text"
                      autoComplete="query"
                      variant="outlined"
                      size="small"
                      onChange={handleQueryChange}
                      InputProps={{
                        startAdornment: startAd(),
                        placeholder: 'Nombre, correo',
                      }}
                      // fullWidth={isMobile}
                    />
                  </div>

                  <TableList
                    className={cStyles.modalTable}
                    resources={buyers}
                    headers={[
                      { key: 'name', label: 'Nombre' },
                      { key: 'email', label: 'Correo' },
                    ]}
                    columns={{
                      name: (res: Buyer) => res.name,
                      email: (res: Buyer) => res.email,
                    }}
                    listActions={{ add: selectBuyer }}
                    listActionsHeaders={(res?: Buyer) => {
                      if (res) return [{ key: 'add', label: 'Seleccionar' }];
                      return [null];
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div>
            {!buyerSelected ? null : (
              <div className={styles.clientForm}>
                <div className={styles.clientFormRow}>
                  <div className={styles.clientFormCell}>
                    <Typography variant="subtitle1">Nombre Cliente*</Typography>
                    <TextField
                      fullWidth
                      required
                      id="buyer.name"
                      type="text"
                      autoComplete="buyer.name"
                      variant="outlined"
                      value={formik.values.buyer.name}
                      onChange={formik.handleChange}
                      error={formik.touched.buyer?.name && Boolean(formik.errors.buyer?.name)}
                      helperText={formik.touched.buyer?.name && formik.errors.buyer?.name}
                    />
                  </div>
                  <div className={styles.clientFormCell}>
                    <Typography variant="subtitle1">Correo Cliente*</Typography>
                    <TextField
                      fullWidth
                      required
                      id="buyer.email"
                      type="text"
                      autoComplete="buyer.email"
                      variant="outlined"
                      value={formik.values.buyer.email}
                      onChange={formik.handleChange}
                      error={formik.touched.buyer?.email && Boolean(formik.errors.buyer?.email)}
                      helperText={formik.touched.buyer?.email && formik.errors.buyer?.email}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {!paymentPlan && formik.values.emit_document && invoiceConfigurationState.state && (
            <Fragment>
              <div>
                <Typography variant="h6" color={variables.primaryDarkerColor} mt={4}>
                  <b>Datos emisión documento</b>
                </Typography>
              </div>
              {invoiceConfigurationState.state.document === 'selectable' && (
                <Grid item xs={isMobile ? 12 : 6} className={styles.fieldContainer}>
                  <Typography variant="subtitle1">
                    Documento <span className={styles.mandatoryText}>*</span>
                  </Typography>
                  <TextField
                    className={styles.inputField}
                    id="invoiceDocument"
                    fullWidth
                    select
                    variant="outlined"
                    name="invoiceDocument"
                    defaultValue="receipt"
                    value={invoiceDocumentState.state}
                    onChange={(event) => invoiceDocumentState.setState(event.target.value)}
                  >
                    <MenuItem value="receipt">Boleta</MenuItem>
                    <MenuItem value="invoice">Factura</MenuItem>
                  </TextField>
                </Grid>
              )}
              {invoiceDocumentState.state === 'invoice' ? (
                <Fragment>
                  <Grid item xs={isMobile ? 12 : 6} className={styles.fieldContainer}>
                    <Typography variant="subtitle1">
                      Rut <span className={styles.mandatoryText}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      id="rut"
                      type="text"
                      variant="outlined"
                      value={invoiceInformationFormik.values.rut}
                      onChange={(e) =>
                        invoiceInformationFormik.setFieldValue('rut', formatRUT(e.target.value))
                      }
                      InputLabelProps={{ shrink: !!invoiceInformationFormik.values.rut }}
                      error={Boolean(invoiceInformationFormik.errors?.rut)}
                      helperText={invoiceInformationFormik.errors?.rut}
                    />
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 6} className={styles.fieldContainer}>
                    <Typography variant="subtitle1">
                      Razón Social <span className={styles.mandatoryText}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      id="business_name"
                      type="text"
                      autoComplete="business_name"
                      variant="outlined"
                      value={invoiceInformationFormik.values.business_name}
                      onChange={invoiceInformationFormik.handleChange}
                      error={
                        invoiceInformationFormik.touched.business_name &&
                        Boolean(invoiceInformationFormik.errors?.business_name)
                      }
                      helperText={
                        invoiceInformationFormik.touched.business_name &&
                        invoiceInformationFormik.errors?.business_name
                      }
                    />
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 6} className={styles.fieldContainer}>
                    <Typography variant="subtitle1">
                      Giro <span className={styles.mandatoryText}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      id="activity"
                      type="text"
                      autoComplete="activity"
                      variant="outlined"
                      value={invoiceInformationFormik.values.activity}
                      onChange={invoiceInformationFormik.handleChange}
                      error={
                        invoiceInformationFormik.touched.activity &&
                        Boolean(invoiceInformationFormik.errors?.activity)
                      }
                      helperText={
                        invoiceInformationFormik.touched.activity &&
                        invoiceInformationFormik.errors?.activity
                      }
                    />
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 6} className={styles.fieldContainer}>
                    <Typography variant="subtitle1">
                      Región <span className={styles.mandatoryText}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      select
                      id="region"
                      type="text"
                      autoComplete="region"
                      variant="outlined"
                      value={invoiceInformationFormik.values.region}
                      onChange={(event) =>
                        invoiceInformationFormik.setFieldValue('region', event.target.value)
                      }
                      error={
                        invoiceInformationFormik.touched.region &&
                        Boolean(invoiceInformationFormik.errors?.region)
                      }
                      helperText={
                        invoiceInformationFormik.touched.region &&
                        invoiceInformationFormik.errors?.region
                      }
                    >
                      {REGIONS.map((region) => (
                        <MenuItem key={region[0]} value={region[1]}>
                          {region[1]}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 6} className={styles.fieldContainer}>
                    <Typography variant="subtitle1">
                      Comuna <span className={styles.mandatoryText}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      select
                      id="commune"
                      type="text"
                      autoComplete="commune"
                      variant="outlined"
                      value={invoiceInformationFormik.values.commune}
                      onChange={(event) =>
                        invoiceInformationFormik.setFieldValue('commune', event.target.value)
                      }
                      error={
                        invoiceInformationFormik.touched.commune &&
                        Boolean(invoiceInformationFormik.errors?.commune)
                      }
                      helperText={
                        invoiceInformationFormik.touched.commune &&
                        invoiceInformationFormik.errors?.commune
                      }
                    >
                      {invoiceInformationFormik.values.region ? (
                        COMMUNES_BY_REGION[invoiceInformationFormik.values.region]
                          ?.sort()
                          .map((commune) => (
                            <MenuItem key={commune} value={commune}>
                              {commune}
                            </MenuItem>
                          ))
                      ) : (
                        <MenuItem>Selecciona región</MenuItem>
                      )}
                    </TextField>
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 6} className={styles.fieldContainer}>
                    <Typography variant="subtitle1">
                      Dirección <span className={styles.mandatoryText}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      id="address"
                      type="text"
                      autoComplete="address"
                      variant="outlined"
                      value={invoiceInformationFormik.values.address}
                      onChange={invoiceInformationFormik.handleChange}
                      error={
                        invoiceInformationFormik.touched.address &&
                        Boolean(invoiceInformationFormik.errors?.address)
                      }
                      helperText={
                        invoiceInformationFormik.touched.address &&
                        invoiceInformationFormik.errors?.address
                      }
                    />
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 6} className={styles.fieldContainer}>
                    <Typography variant="subtitle1">Email contacto</Typography>
                    <TextField
                      fullWidth
                      id="email"
                      type="text"
                      autoComplete="email"
                      variant="outlined"
                      value={invoiceInformationFormik.values.email}
                      onChange={invoiceInformationFormik.handleChange}
                      error={
                        invoiceInformationFormik.touched.email &&
                        Boolean(invoiceInformationFormik.errors?.email)
                      }
                      helperText={
                        invoiceInformationFormik.touched.email &&
                        invoiceInformationFormik.errors?.email
                      }
                    />
                  </Grid>
                </Fragment>
              ) : invoiceConfigurationState.state?.ask_rut ? (
                <Fragment>
                  <Grid item xs={isMobile ? 12 : 6} className={styles.fieldContainer}>
                    <Typography variant="subtitle1">
                      Rut <span className={styles.mandatoryText}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      required={true}
                      id="rut"
                      type="text"
                      autoComplete="extra_fields[id]"
                      variant="outlined"
                      value={invoiceInformationFormik.values.rut}
                      onChange={(e) =>
                        invoiceInformationFormik.setFieldValue('rut', formatRUT(e.target.value))
                      }
                      InputLabelProps={{ shrink: !!invoiceInformationFormik.values.rut }}
                      error={Boolean(invoiceInformationFormik.errors?.rut)}
                      helperText={invoiceInformationFormik.errors?.rut}
                    />
                  </Grid>
                </Fragment>
              ) : null}
            </Fragment>
          )}
        </div>
      </Paper>
    </>
  );
};

const StatementsForm = ({
  formik,
  company,
  initialData,
}: FormComponentProps): React.ReactElement => {
  const [statements, setStatements] = useState<Statement[]>([]);
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);

  const addStatement = () => {
    let newDueDate;
    let newAmount;
    let newExternalAmount;
    if (statements[statements.length - 1]) {
      const auxDate = new Date([statements[statements.length - 1].due_date, 'T00:00:00'].join(''));
      const constAuxDate = new Date(auxDate.valueOf());
      newDueDate = new Date(auxDate.setMonth(auxDate.getMonth() + 1));
      if (constAuxDate.getDate() !== newDueDate.getDate()) {
        if (constAuxDate.getMonth() === 0) {
          // mes anterior es enero
          newDueDate = new Date(newDueDate.getFullYear(), 1, 28);
        } else {
          newDueDate = new Date(
            newDueDate.getFullYear(),
            newDueDate.getMonth() - 1,
            constAuxDate.getDate() - 1
          );
        }
      }
      newAmount = statements[statements.length - 1].amount;
      newExternalAmount = statements[statements.length - 1].amount;
    }
    if (newDueDate) newDueDate = DateTime.fromJSDate(newDueDate).toISODate();
    setStatements([
      ...statements,
      {
        amount: newAmount || 0,
        due_date: newDueDate || '',
        currency: statements[statements.length - 1]?.currency || 'CLP',
        company_id: company?.id,
        external_amount: newExternalAmount,
      } as Statement,
    ]);
  };

  const changeAmount = (event: any, index: number, currency?: string) => {
    const auxArray = statements;
    const amount =
      currency === 'UF'
        ? (decimalNumberFormatter(event.target.value) as unknown as number)
        : parseInt(plainNumberFormatter(event.target.value)) || 0;
    auxArray[index] = {
      ...auxArray[index],
      amount: amount,
      external_amount: amount,
    };
    setStatements([...auxArray]);
  };

  const changeDueDate = (event: any, index: number) => {
    const auxArray = statements;
    auxArray[index] = { ...auxArray[index], due_date: event.target.value } as Statement;
    setStatements([...auxArray]);
  };

  const changeCurrency = (event: any, index: number) => {
    const auxArray = statements;
    auxArray[index] = { ...auxArray[index], currency: event.target.value } as Statement;
    setStatements([...auxArray]);
  };

  const deleteStatement = (index: number) => {
    const auxArray = statements;
    auxArray.splice(index, 1);
    setStatements([...auxArray]);
  };

  useEffect(() => formik.setFieldValue('statements', statements), [statements]);

  return (
    <Fragment>
      {initialData ? null : (
        <Paper className={styles.mainPaper}>
          <div className={styles.mainEditContainer}>
            <div className={styles.title}>
              <Typography variant="h6">Configurar cuotas</Typography>
              <Button onClick={addStatement} variant="outlined">
                Agregar Cuota
              </Button>
            </div>
          </div>
          <div className={styles.statementsContainer}>
            <Stack spacing={2} direction="column" alignItems="center">
              {statements.map((statement, index) => (
                <div className={styles.statementRow} key={index}>
                  <TextField
                    select
                    defaultValue={statement.currency || 'CLP'}
                    variant="standard"
                    label="Moneda"
                    onChange={(event) => changeCurrency(event, index)}
                  >
                    {CURRENCIES.map((curr: string) => (
                      <MenuItem key={curr} value={curr}>
                        {curr}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {statement.currency === 'CLP' ? '$' : null}
                        </InputAdornment>
                      ),
                    }}
                    value={
                      statement.currency === 'UF'
                        ? formatDecimalNumber(statement.external_amount)
                        : formatNumber(statement.amount)
                    }
                    onChange={(event) => changeAmount(event, index, statement.currency)}
                    label="Monto"
                    required
                  />
                  <TextField
                    variant="standard"
                    placeholder="Fecha de vencimiento"
                    type="date"
                    value={statement.due_date}
                    onChange={(event) => changeDueDate(event, index)}
                    label="Fecha de vencimiento"
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                  <div className={styles.trashicon} onClick={() => deleteStatement(index)}>
                    {isMobile ? (
                      <Button onClick={() => deleteStatement(index)} variant="outlined">
                        Eliminar cuota
                      </Button>
                    ) : (
                      <FontAwesomeIcon icon={faTrashCan} />
                    )}
                  </div>
                </div>
              ))}
            </Stack>
          </div>
        </Paper>
      )}
    </Fragment>
  );
};

const BankInformationForm = (props: FormComponentProps): React.ReactElement => {
  const [bankInformations, setBankInformations] = useState<BankInformation[]>([]);
  const [view, setView] = useState<string>('default');
  const [hideForm, setHideForm] = useState<boolean>(false);
  const [defaultBankInformation, setDefaultBankInformation] = useState<BankInformation>();

  useEffect(() => {
    sellerApi.bankInformations
      .index(props.company?.id)
      .then((data) => setBankInformations(data.data));
  }, []);

  const changeView = (context: string) => {
    const fields = ['name', 'tax_id', 'bank', 'account_type', 'account_number', 'email', 'id'];
    setView(context);
    if (context === 'new') {
      fields.forEach((field) => props.formik.setFieldValue(`bank_information.${field}`, ''));
      props.formik.setFieldValue('bank_information.default', false);
      setHideForm(false);
      props.formik.setFieldValue('bank_information.id', '');
    } else if (context === 'default') {
      fields.forEach((field) => {
        props.formik.setFieldValue(
          `bank_information.${field}`,
          defaultBankInformation?.[field as keyof BankInformation]
        );
      });
      props.formik.setFieldValue('bank_information.default', true);
      setHideForm(false);
    } else if (context === 'existant') {
      setHideForm(true);
      props.formik.setFieldValue('bank_information.id', '');
    }
  };

  const selectBankInformation = (object: BankInformation) => {
    const fields = ['name', 'tax_id', 'bank', 'account_type', 'account_number', 'email', 'id'];
    fields.forEach((field) =>
      props.formik.setFieldValue(
        `bank_information.${field}`,
        object[field as keyof BankInformation]
      )
    );
    props.formik.setFieldValue('bank_information.default', false);
    setHideForm(false);
  };

  useEffect(() => {
    if (props.initialData?.bank_information?.id) {
      setView('existant');
      setHideForm(false);
    }
  }, [props.initialData?.bank_information]);

  const loadBankInformations = () => {
    if (props.company?.id) {
      sellerApi.bankInformations
        .index(props.company.id)
        .then((data: ApiList<BankInformation>) => {
          setBankInformations([...data.data]);
          data.data.forEach((bi: BankInformation) => {
            if (bi.default) setDefaultBankInformation(bi);
          });
        })
        .catch(console.error);
    }
  };

  useEffect(() => {
    loadBankInformations();
  }, [props.company?.id]);

  return (
    <Paper className={styles.mainPaper}>
      <div className={styles.mainEditContainer}>
        <div className={styles.titleAdvanced}>
          <Typography variant="h6">Datos bancarios</Typography>
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => changeView('default')}
              disabled={
                view === 'default' ||
                props.formik.values.bank_information?.id === defaultBankInformation?.id
              }
            >
              Usar datos por defecto
            </Button>
            <Button onClick={() => changeView('existant')} disabled={hideForm}>
              Buscar otros datos
            </Button>
            <Button onClick={() => changeView('new')} disabled={view === 'new'}>
              Crear nuevos datos
            </Button>
          </ButtonGroup>
        </div>
        <Typography variant="subtitle1" className={styles.subtitle}>
          Te transferiremos lo recaudado a los datos que selecciones acá.
        </Typography>
      </div>
      <div className={styles.content}>
        {!hideForm && (
          <div className={styles.clientForm}>
            <div className={styles.clientFormRow}>
              <div className={styles.clientFormCell}>
                <Typography variant="subtitle1">Nombre*</Typography>
                <TextField
                  fullWidth
                  required
                  value={props.formik.values.bank_information?.name}
                  id="bank_information.name"
                  type="text"
                  autoComplete="bank_information.name"
                  variant="outlined"
                  onChange={props.formik.handleChange}
                  error={
                    getIn(props.formik.touched, 'bank_information.name') &&
                    Boolean(getIn(props.formik.errors, 'bank_information.name'))
                  }
                  helperText={
                    getIn(props.formik.touched, 'bank_information.name') &&
                    getIn(props.formik.errors, 'bank_information.name')
                  }
                />
              </div>
              <div className={styles.clientFormCell}>
                <Typography variant="subtitle1">Rut*</Typography>
                <TextField
                  fullWidth
                  required
                  value={props.formik.values.bank_information?.tax_id}
                  id="bank_information.tax_id"
                  type="text"
                  autoComplete="bank_information.tax_id"
                  variant="outlined"
                  onChange={RUTFormater(props.formik.handleChange)}
                  error={
                    getIn(props.formik.touched, 'bank_information.tax_id') &&
                    Boolean(getIn(props.formik.errors, 'bank_information.tax_id'))
                  }
                  helperText={
                    getIn(props.formik.touched, 'bank_information.tax_id') &&
                    getIn(props.formik.errors, 'bank_information.tax_id')
                  }
                />
              </div>
            </div>
            <div className={styles.clientFormRow}>
              <div className={styles.clientFormCell}>
                <Typography variant="subtitle1">Banco*</Typography>
                <TextField
                  fullWidth
                  required
                  select
                  value={props.formik.values.bank_information?.bank}
                  id="bank_information.bank"
                  type="text"
                  autoComplete="bank_information.bank"
                  variant="outlined"
                  onChange={(event: any) =>
                    props.formik.setFieldValue('bank_information.bank', event.target.value)
                  }
                  error={
                    getIn(props.formik.touched, 'bank_information.bank') &&
                    Boolean(getIn(props.formik.errors, 'bank_information.bank'))
                  }
                  helperText={
                    getIn(props.formik.touched, 'bank_information.bank') &&
                    getIn(props.formik.errors, 'bank_information.bank')
                  }
                >
                  {BANKS.map((bank: string) => (
                    <MenuItem key={bank} value={bank}>
                      {bank}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className={styles.clientFormCell}>
                <Typography variant="subtitle1">Tipo de cuenta*</Typography>
                <TextField
                  fullWidth
                  required
                  select
                  value={props.formik.values.bank_information?.account_type}
                  id="bank_information.account_type"
                  type="text"
                  autoComplete="bank_information.account_type"
                  variant="outlined"
                  onChange={(event: any) =>
                    props.formik.setFieldValue('bank_information.account_type', event.target.value)
                  }
                  error={
                    getIn(props.formik.touched, 'bank_information.account_type') &&
                    Boolean(getIn(props.formik.errors, 'bank_information.account_type'))
                  }
                  helperText={
                    getIn(props.formik.touched, 'bank_information.account_type') &&
                    getIn(props.formik.errors, 'bank_information.account_type')
                  }
                >
                  {ACCOUNT_TYPES.map((type: string) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                  {props.formik.values.bank_information?.bank === 'Banco Estado' && (
                    <MenuItem value="Cuenta Vista">Cuenta Rut</MenuItem>
                  )}
                </TextField>
              </div>
            </div>
            <div className={styles.clientFormRow}>
              <div className={styles.clientFormCell}>
                <Typography variant="subtitle1">Número de cuenta*</Typography>
                <TextField
                  fullWidth
                  required
                  value={props.formik.values.bank_information?.account_number}
                  id="bank_information.account_number"
                  type="text"
                  autoComplete="bank_information.account_number"
                  variant="outlined"
                  onChange={props.formik.handleChange}
                  error={
                    getIn(props.formik.touched, 'bank_information.account_number') &&
                    Boolean(getIn(props.formik.errors, 'bank_information.account_number'))
                  }
                  helperText={
                    getIn(props.formik.touched, 'bank_information.account_number') &&
                    getIn(props.formik.errors, 'bank_information.account_number')
                  }
                />
              </div>
              <div className={styles.clientFormCell}>
                <Typography variant="subtitle1">Correo</Typography>
                <TextField
                  fullWidth
                  value={props.formik.values.bank_information?.email}
                  id="bank_information.email"
                  type="text"
                  autoComplete="bank_information.email"
                  variant="outlined"
                  onChange={props.formik.handleChange}
                  error={
                    getIn(props.formik.touched, 'bank_information.email') &&
                    Boolean(getIn(props.formik.errors, 'bank_information.email'))
                  }
                  helperText={
                    getIn(props.formik.touched, 'bank_information.email') &&
                    getIn(props.formik.errors, 'bank_information.email')
                  }
                />
              </div>
            </div>
          </div>
        )}
        {hideForm && (
          <TableList
            className={cStyles.modalTable}
            resources={bankInformations}
            headers={[
              { key: 'name', label: 'Nombre' },
              { key: 'tax_id', label: 'RUT' },
            ]}
            columns={{
              name: (res: BankInformation) => res.name,
              tax_id: (res: BankInformation) => res.tax_id,
            }}
            listActions={{ add: selectBankInformation }}
            listActionsHeaders={(res?: BankInformation) => {
              if (res) return [{ key: 'add', label: 'Seleccionar' }];
              return [null];
            }}
          />
        )}
      </div>
    </Paper>
  );
};

const BillingInformationForm = (props: FormComponentProps): React.ReactElement => {
  const [view, setView] = useState<string>('default');
  const [hideForm, setHideForm] = useState<boolean>(false);
  const [billingInformations, setBillingInformations] = useState<BillingInformation[]>([]);
  const [defaultBillingInformation, setDefaultBillingInformation] = useState<BillingInformation>();

  const changeView = (context: string) => {
    const fields = ['tax_id', 'business_name', 'address', 'commune', 'activity', 'email', 'id'];
    setView(context);
    if (context === 'new') {
      fields.forEach((field) => props.formik.setFieldValue(`billing_information.${field}`, ''));
      props.formik.setFieldValue('billing_information.default', false);
      props.formik.setFieldValue('billing_information.id', '');
      setHideForm(false);
    } else if (context === 'default') {
      fields.forEach((field) => {
        props.formik.setFieldValue(
          `billing_information.${field}`,
          defaultBillingInformation?.[field as keyof BillingInformation]
        );
      });
      props.formik.setFieldValue('billing_information.default', true);
      setHideForm(false);
    } else if (context === 'existant') {
      setHideForm(true);
      props.formik.setFieldValue('billing_information.id', '');
    }
  };

  const loadBillingInformations = () => {
    if (props.company?.id) {
      sellerApi.billingInformations
        .index(props.company.id)
        .then((data: ApiList<BillingInformation>) => {
          setBillingInformations([...data.data]);
          data.data.forEach((bi: BillingInformation) => {
            if (bi.default) setDefaultBillingInformation(bi);
          });
        })
        .catch(console.error);
    }
  };

  useEffect(() => {
    loadBillingInformations();
  }, [props.company?.id]);

  const selectBillingInformation = (object: BillingInformation) => {
    const fields = [
      'id',
      'company_id',
      'tax_id',
      'business_name',
      'address',
      'commune',
      'activity',
      'email',
    ];
    fields.forEach((field) =>
      props.formik.setFieldValue(
        `billing_information.${field}`,
        object[field as keyof BillingInformation]
      )
    );
    props.formik.setFieldValue('billing_information.default', false);
    setHideForm(false);
  };

  useEffect(() => {
    if (props.initialData?.billing_information?.id) {
      setView('existant');
      setHideForm(false);
    }
  }, [props.initialData?.billing_information]);

  return (
    <Paper className={styles.mainPaper}>
      <div className={styles.mainEditContainer}>
        <div className={styles.titleAdvanced}>
          <Typography variant="h6">Datos de facturación</Typography>
          <ButtonGroup variant="outlined">
            <Button
              onClick={() => changeView('default')}
              disabled={
                view === 'default' ||
                props.formik.values.billing_information?.id === defaultBillingInformation?.id
              }
            >
              Usar datos por defecto
            </Button>
            <Button onClick={() => changeView('existant')} disabled={hideForm}>
              Buscar otros datos
            </Button>
            <Button onClick={() => changeView('new')} disabled={view === 'new'}>
              Crear nuevos datos
            </Button>
          </ButtonGroup>
        </div>
        <Typography variant="subtitle1" className={styles.subtitle}>
          Te emitiremos facturas a estos datos
        </Typography>
      </div>
      {!hideForm && (
        <div className={styles.content}>
          <div className={styles.clientForm}>
            <div className={styles.clientFormRow}>
              <div className={styles.clientFormCell}>
                <Typography variant="subtitle1">Razón social*</Typography>
                <TextField
                  fullWidth
                  required
                  id="billing_information.business_name"
                  type="text"
                  autoComplete="billing_information.business_name"
                  variant="outlined"
                  value={props.formik.values.billing_information?.business_name}
                  onChange={props.formik.handleChange}
                  error={
                    getIn(props.formik.touched, 'billing_information.business_name') &&
                    Boolean(getIn(props.formik.errors, 'billing_information.business_name'))
                  }
                  helperText={
                    getIn(props.formik.touched, 'billing_information.business_name') &&
                    getIn(props.formik.errors, 'billing_information.business_name')
                  }
                />
              </div>
              <div className={styles.clientFormCell}>
                <Typography variant="subtitle1">Rut*</Typography>
                <TextField
                  fullWidth
                  required
                  id="billing_information.tax_id"
                  type="text"
                  autoComplete="billing_information.tax_id"
                  variant="outlined"
                  value={props.formik.values.billing_information?.tax_id}
                  onChange={RUTFormater(props.formik.handleChange)}
                  error={
                    getIn(props.formik.touched, 'billing_information.tax_id') &&
                    Boolean(getIn(props.formik.errors, 'billing_information.tax_id'))
                  }
                  helperText={
                    getIn(props.formik.touched, 'billing_information.tax_id') &&
                    getIn(props.formik.errors, 'billing_information.tax_id')
                  }
                />
              </div>
            </div>
            <div className={styles.clientFormRow}>
              <div className={styles.clientFormCell}>
                <Typography variant="subtitle1">Comuna*</Typography>
                <Autocomplete
                  id="billing_information.commune"
                  value={props.formik.values.billing_information?.commune}
                  onChange={(e, value) =>
                    props.formik.setFieldValue('billing_information.commune', value)
                  }
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoComplete="billing_information.commune"
                      required
                      error={
                        getIn(props.formik.touched, 'billing_information.commune') &&
                        Boolean(getIn(props.formik.errors, 'billing_information.commune'))
                      }
                      helperText={
                        getIn(props.formik.touched, 'billing_information.commune') &&
                        getIn(props.formik.errors, 'billing_information.commune')
                      }
                      name="billing_information.commune"
                      type="text"
                      variant="outlined"
                    />
                  )}
                  options={COMMUNES}
                />
              </div>
              <div className={styles.clientFormCell}>
                <Typography variant="subtitle1">Dirección*</Typography>
                <TextField
                  fullWidth
                  required
                  id="billing_information.address"
                  type="text"
                  autoComplete="billing_information.address"
                  variant="outlined"
                  value={props.formik.values.billing_information?.address}
                  onChange={props.formik.handleChange}
                  error={
                    getIn(props.formik.touched, 'billing_information.address') &&
                    Boolean(getIn(props.formik.errors, 'billing_information.address'))
                  }
                  helperText={
                    getIn(props.formik.touched, 'billing_information.address') &&
                    getIn(props.formik.errors, 'billing_information.address')
                  }
                />
              </div>
            </div>
            <div className={styles.clientFormRow}>
              <div className={styles.clientFormCell}>
                <Typography variant="subtitle1">Actividad*</Typography>
                <TextField
                  fullWidth
                  required
                  id="billing_information.activity"
                  type="text"
                  autoComplete="billing_information.activity"
                  variant="outlined"
                  value={props.formik.values.billing_information?.activity}
                  onChange={props.formik.handleChange}
                  error={
                    getIn(props.formik.touched, 'billing_information.activity') &&
                    Boolean(getIn(props.formik.errors, 'billing_information.activity'))
                  }
                  helperText={
                    getIn(props.formik.touched, 'billing_information.activity') &&
                    getIn(props.formik.errors, 'billing_information.activity')
                  }
                />
              </div>
              <div className={styles.clientFormCell}>
                <Typography variant="subtitle1">Correo</Typography>
                <TextField
                  fullWidth
                  id="billing_information.email"
                  type="text"
                  autoComplete="billing_information.email"
                  variant="outlined"
                  value={props.formik.values.billing_information?.email}
                  onChange={props.formik.handleChange}
                  error={
                    getIn(props.formik.touched, 'billing_information.email') &&
                    Boolean(getIn(props.formik.errors, 'billing_information.email'))
                  }
                  helperText={
                    getIn(props.formik.touched, 'billing_information.email') &&
                    getIn(props.formik.errors, 'billing_information.email')
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {hideForm && (
        <TableList
          className={cStyles.modalTable}
          resources={billingInformations}
          headers={[
            { key: 'name', label: 'Razón social' },
            { key: 'tax_id', label: 'RUT' },
          ]}
          columns={{
            name: (res: BillingInformation) => res.business_name,
            tax_id: (res: BillingInformation) => res.tax_id,
          }}
          listActions={{ add: selectBillingInformation }}
          listActionsHeaders={(res?: BillingInformation) => {
            if (res) return [{ key: 'add', label: 'Seleccionar' }];
            return [null];
          }}
        />
      )}
    </Paper>
  );
};

export default PaymentPlanForm;
