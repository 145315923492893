// Company API
import * as companies from './company';
import * as products from './product';
import * as buyers from './buyer';
import * as bankInformations from './bankInformation';
import * as statements from './statement';
import * as subscriptions from './subscription';
import * as payment_designs from './paymentDesign';
import * as invoices from './invoices';
import * as paymentPlans from './paymentPlans';
import * as billingInformations from './billingInformation';
import * as paymentMethods from './paymentMethod';
import * as sellers from './sellers';
import * as subscriptionBuyers from './subscriptionBuyer';
import * as singlePayments from './singlePayment';
import * as singlePaymentBuyers from './singlePaymentBuyer';
import * as buyerEmailConfiguartion from './buyerEmailConfiguration';
import * as companyEmailConfiguration from './companyEmailConfiguration';
import * as defaultExtraField from './defaultExtraField';
import * as transfers from './transfers';

export {
  companies,
  products,
  buyers,
  bankInformations,
  statements,
  subscriptions,
  payment_designs,
  invoices,
  paymentPlans,
  billingInformations,
  paymentMethods,
  sellers,
  subscriptionBuyers,
  singlePayments,
  singlePaymentBuyers,
  buyerEmailConfiguartion,
  companyEmailConfiguration,
  defaultExtraField,
  transfers,
};

export default {
  companies,
  products,
  buyers,
  bankInformations,
  statements,
  subscriptions,
  payment_designs,
  invoices,
  paymentPlans,
  billingInformations,
  paymentMethods,
  sellers,
  subscriptionBuyers,
  singlePayments,
  singlePaymentBuyers,
  buyerEmailConfiguartion,
  companyEmailConfiguration,
  defaultExtraField,
  transfers,
};
