import {
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  Box,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { es } from 'yup-locales';

import { useSelector } from 'react-redux';
import { ClientState } from '../clientSlice';

import PopUp from '../../../common/components/PopUp';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { Kushki } from '@kushki/js';
import kushkiLogo from '../../../assets/images/seller/payment/kushki.svg';
import KushkiCardForm from '../../../common/components/KushkiCardForm';

import { clientApi } from '../../../common/api';
import {
  Card,
  PaymentPlan,
  ApiList,
  PAC,
  GenericObject,
  CardInfo,
  TokenKushkiPayRequest,
  KushkiResponse,
} from '../../../app/type';
import { useSnackbar } from 'notistack';

import styles from '../Client.module.scss';
import { useHistory } from 'react-router-dom';
import { hexToRgb } from '../utils';

interface PopUpProps {
  state: {
    open: boolean;
    setOpen: (s: boolean) => void;
  };
  projectName?: string;
  card: string;
  productId?: string;
  show?: boolean;
  updateCard?: boolean;
  isPaymentPlan?: boolean;
  cards: Card[];
  PACs: PAC[];
}

const kushki = new Kushki({
  merchantId:
    process.env.NODE_ENV === 'production'
      ? '1804b8f0b64549adb59dd76c6736124d'
      : 'c417cbc305344419a2c765e11ac7c7b3',
  inTestEnvironment: process.env.NODE_ENV !== 'production',
});
yup.setLocale(es);

export const CardsPopup = ({
  state,
  projectName,
  card,
  productId,
  show,
  updateCard,
  isPaymentPlan,
  cards,
  PACs,
}: PopUpProps): React.ReactElement => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(card || '');
  const [loading, setLoading] = useState<boolean>(false);
  const [addCardLoading, setAddCardLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [renderKushkiForm, setRenderKushkiForm] = useState<boolean>(false);
  const [oneclickResponse, setOneclickResponse] = useState<any>({});
  const [paymentPlans, setPaymentPlans] = useState<Partial<PaymentPlan>[]>([]);
  const { client, company } = useSelector(({ client }: { client: ClientState }) => client);
  const oneclickRef = useRef<HTMLFormElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const rgbColor = company?.payment_design?.background_color
    ? hexToRgb(company?.payment_design?.background_color)
    : null;
  const cardsBackgroundColor = rgbColor
    ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`
    : '#edeeff';

  const productRequest = isPaymentPlan ? clientApi.paymentPlans : clientApi.subscriptionBuyers;
  const productType = isPaymentPlan ? 'PaymentPlan' : 'SubscriptionBuyer';

  const cardSchema = yup.object().shape({
    card_number: yup
      .string()
      .required('Número de tarjeta requerido')
      .max(19, 'Debe tener un largo máximo de 16 dígitos'),
    name: yup.string().required('Nombre del titular es requerido'),
    expiration_date: yup
      .string()
      .required('Fecha de vencimiento requerida')
      .min(5, 'Fecha inválida'),
    cvv: yup.string().required('CVV requerido').min(3, 'CVV inválido'),
  });

  const formik = useFormik<CardInfo>({
    initialValues: {
      card_number: '',
      name: '',
      expiration_date: '',
      cvv: undefined,
    },
    validationSchema: () => cardSchema,
    onSubmit: () => {
      setSaveLoading(true);
      setAddCardLoading(true);
      kushki.requestSubscriptionToken(
        {
          currency: 'CLP',
          card: {
            name: formik.values.name,
            number: formik.values.card_number.split(' ').join(''),
            cvc: formik.values.cvv,
            expiryMonth: formik.values.expiration_date.slice(0, 2),
            expiryYear: formik.values.expiration_date.slice(3, 5),
          },
        } as TokenKushkiPayRequest,
        (response: KushkiResponse) => {
          if (!response.code) {
            clientApi.kushkiCards
              .create(
                client?.id || '',
                productId,
                updateCard ? card : undefined,
                productType,
                response.token
              )
              .then((data: any) => {
                if (data.data.approved) {
                  state.setOpen(false);
                  history.push(data.data.redirect_to);
                  enqueueSnackbar('Tarjeta creada exitosamente', { variant: 'success' });
                } else {
                  enqueueSnackbar('Ocurrió un error al agregar la tarjeta, intenta nuevamente', {
                    variant: 'error',
                  });
                  console.error(response.error);
                }
              })
              .catch(() => {
                enqueueSnackbar('Ocurrió un error al agregar la tarjeta, intenta nuevamente', {
                  variant: 'error',
                });
              })
              .finally(() => {
                setAddCardLoading(false);
                setSaveLoading(false);
                setRenderKushkiForm(false);
              });
          } else {
            setSaveLoading(false);
            setAddCardLoading(false);
            enqueueSnackbar('Ocurrió un error al agregar la tarjeta, intenta nuevamente', {
              variant: 'error',
            });
            console.error(response.error);
          }
        }
      );
    },
  });

  const handleAddCard = () => {
    if (!addCardLoading) {
      if (company?.payment_methods?.includes('oneclick')) {
        setAddCardLoading(true);
        clientApi.webpayCards
          .create(client?.id || '', productId, updateCard ? card : undefined, productType)
          .then((data: GenericObject) => {
            setOneclickResponse(data.data);
          })
          .catch(console.error)
          .finally(() => setAddCardLoading(false));
      } else {
        setSelectedPaymentMethod('');
        setRenderKushkiForm(true);
      }
    }
  };

  const handleSubmit = () => {
    if (saveLoading) return;
    if (renderKushkiForm) {
      formik.submitForm();
    } else if (productId) {
      setSaveLoading(true);
      assignCardRequest();
    } else if (updateCard) {
      setSaveLoading(true);
      updateCardRequest();
    }
  };

  const assignCardRequest = () => {
    productRequest
      .assignCard(selectedPaymentMethod, productId || '', client?.id || '')
      .then(() => {
        state.setOpen(false);
        setSaveLoading(false);
        history.replace(
          `/client/products?new_assignment=success${
            show ? `&show=${isPaymentPlan ? 'payment_plan' : 'recurrent'}/${productId}` : ''
          }`
        );
        enqueueSnackbar('Tarjeta asignada exitosamente', { variant: 'success' });
      })
      .catch(() => {
        state.setOpen(false);
        setSaveLoading(false);
        enqueueSnackbar('La tarjeta no pudo ser asignada, intenta nuevamente', {
          variant: 'error',
        });
        console.error;
      });
  };

  const updateCardRequest = () => {
    clientApi.cards
      .assignPaymentPlans(client?.id || '', selectedPaymentMethod, card)
      .then(() => {
        state.setOpen(false);
        setSaveLoading(false);
        history.replace(`/client/products?new_assignment=success`);
        enqueueSnackbar('Tarjeta asignada exitosamente', { variant: 'success' });
      })
      .catch(() => {
        state.setOpen(false);
        setSaveLoading(false);
        enqueueSnackbar('La tarjeta no pudo ser asignada, intenta nuevamente', {
          variant: 'error',
        });
        console.error;
      });
  };

  const cardsToShow = updateCard ? cards.filter((obj) => obj.id !== card) : cards;
  const PACsToShow = updateCard ? PACs.filter((obj) => obj.id !== card) : PACs;

  useEffect(() => {
    if (updateCard && card) {
      clientApi.cards
        .paymentPlans(client?.id || '', card)
        .then((data: ApiList<Partial<PaymentPlan>>) => {
          setPaymentPlans(data.data);
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [card]);

  useEffect(() => {
    if (oneclickResponse.url && oneclickRef?.current) oneclickRef?.current.submit();
  }, [oneclickResponse]);

  useEffect(() => {
    if (selectedPaymentMethod && renderKushkiForm) setRenderKushkiForm(false);
  }, [selectedPaymentMethod]);

  return (
    <PopUp
      state={state}
      title={
        <div className={styles.titlePopup}>
          <Typography>{updateCard ? 'Actualizar tarjeta' : 'Editar medio de pago'}</Typography>
        </div>
      }
      content={
        <div className={styles.popupContainer}>
          {loading ? (
            <div className={styles.spinnerContainer}>
              <Box sx={{ display: 'flex' }}>
                <CircularProgress
                  sx={{
                    color: company?.payment_design?.background_color
                      ? company?.payment_design?.background_color
                      : '#4653e3',
                  }}
                />
              </Box>
            </div>
          ) : updateCard ? (
            <div className={styles.updateCardTitle}>
              <Typography>Se modificarán las tarjetas de los siguientes productos:</Typography>
              {paymentPlans.map((pp) => (
                <Typography key={pp.id}>
                  <li>{pp.product?.name}</li>
                </Typography>
              ))}
            </div>
          ) : (
            <div className={styles.titleHeader}>
              <div className={styles.icon}>
                <div
                  className={styles.buildingIconCircle}
                  style={{
                    backgroundColor: company?.payment_design?.background_color
                      ? company?.payment_design?.background_color
                      : '#4653e3',
                  }}
                >
                  <FontAwesomeIcon icon={faBoxOpen} className={styles.buildingIcon} />
                </div>
              </div>
              <Typography>{projectName}</Typography>
            </div>
          )}
          <div className={styles.cardsBox}>
            <Typography>
              {updateCard
                ? 'Agrega una nueva tarjeta o selecciona otra para reemplazar la rechazada'
                : 'Selecciona el medio de pago con el que deseas pagar'}
            </Typography>
            <div className={styles.cardsColumn}>
              <FormGroup>
                <RadioGroup
                  name="Cards options"
                  value={selectedPaymentMethod}
                  onChange={(event: any) => setSelectedPaymentMethod(event.target.value)}
                >
                  {cardsToShow?.map((card: Card) => (
                    <div
                      className={styles.card}
                      key={card.id}
                      style={{
                        background: cardsBackgroundColor,
                      }}
                    >
                      <div>
                        <FormControlLabel
                          value={card.id}
                          control={<Radio />}
                          label={
                            <div className={styles.cardRow}>
                              <div className={styles.cardIcon}>
                                <FontAwesomeIcon
                                  icon={faCreditCard}
                                  className={styles.buildingIcon}
                                />
                              </div>
                              <Typography>{`XXXX XXXX XXXX ${card.last_four_digits}`}</Typography>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  ))}
                  {PACsToShow?.map((pac: PAC) => (
                    <div
                      className={styles.card}
                      key={pac.id}
                      style={{
                        background: cardsBackgroundColor,
                      }}
                    >
                      <div>
                        <FormControlLabel
                          value={pac.id}
                          control={<Radio />}
                          label={
                            <div className={styles.cardRow}>
                              <Typography>{`${pac.account_type || 'PAC'} ••• ${
                                pac.account_number?.slice(-4) || ''
                              }`}</Typography>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  ))}
                  {['oneclick', 'kushki_recurrent'].some((pm) =>
                    company?.payment_methods?.includes(pm)
                  ) && (
                    <div
                      className={`${styles.card} ${styles.newCard}`}
                      key="new-card"
                      onClick={handleAddCard}
                      style={{
                        background: cardsBackgroundColor,
                      }}
                    >
                      {addCardLoading ? (
                        <div className={styles.cardSpinnerContainer}>
                          <Box sx={{ display: 'flex' }}>
                            <CircularProgress
                              size={'25px'}
                              sx={{
                                color: company?.payment_design?.background_color
                                  ? company?.payment_design?.background_color
                                  : '#4653e3',
                              }}
                            />
                          </Box>
                        </div>
                      ) : (
                        <div className={`${styles.cardRow} ${styles.addCard}`}>
                          <div className={styles.cardIcon}>
                            <FontAwesomeIcon icon={faPlus} className={styles.buildingIcon} />
                          </div>
                          <Typography>Agregar tarjeta</Typography>
                        </div>
                      )}
                    </div>
                  )}
                </RadioGroup>
              </FormGroup>
            </div>
            {renderKushkiForm && (
              <div className={styles.kushkiCardContainerPopup}>
                <KushkiCardForm formik={formik} />
                <img className={styles.kushkiLogo} src={kushkiLogo} />
              </div>
            )}
            <div
              className={styles.button}
              onClick={handleSubmit}
              style={{
                background: company?.payment_design?.background_color
                  ? company?.payment_design?.background_color
                  : '#4653e3',
              }}
            >
              {saveLoading ? (
                <div className={styles.whiteSpinnerContainer}>
                  <Box>
                    <CircularProgress
                      size={'20px'}
                      sx={{
                        color: company?.payment_design?.background_color
                          ? company?.payment_design?.background_color
                          : '#4653e3',
                      }}
                    />
                  </Box>
                </div>
              ) : (
                <Typography>Guardar</Typography>
              )}
            </div>
          </div>
          {oneclickResponse.url && oneclickResponse.token && (
            <form method="POST" action={oneclickResponse.url} ref={oneclickRef}>
              <input type="hidden" name="TBK_TOKEN" value={oneclickResponse.token} />
            </form>
          )}
        </div>
      }
    />
  );
};
export default CardsPopup;
